import { memo, useState } from 'react';
import styles from './styles.module.css';
import PageHeader from 'components/PageHeader';
import notify from 'utils/notifications';
import { createNotification, editNotification } from 'api/notifications';

import { useLocation, useNavigate } from 'react-router-dom';
import { SCREENS } from 'constants/navigation';
import Input from 'components/Input';
import { NotificationLanguages, NotificationTypes } from 'enums/notification';
import Select from 'components/Select';
import Button from 'components/Button';
import moment from 'moment';
import DatePicker from "react-datepicker";
import { ReactComponent as Calendar } from './icons/calendar.svg';
import { ReactComponent as Restrict } from './icons/restrict.svg';

const NotificationPage = () => {
  const redirect = useNavigate();
  const { state: { notification } } = useLocation();
  const isNotificationExist = !!notification?.id;

  const [values, setValues] = useState<any>({
    title: notification?.title || '',
    body: notification?.body || '',
    type: notification?.type || NotificationTypes.Promo,
    trigger_datetime: notification?.trigger_datetime || moment(new Date()).format('MM/DD/YYYY HH:mm'),
    link_after_clicking: notification?.link_after_clicking || '',
    users: notification?.users?.join(' ') || '',
    send_to_users_with_language: isNotificationExist && !!notification?.send_to_users_with_language && notification?.send_to_users_with_language !== 'null'
      ? notification?.send_to_users_with_language
      : NotificationLanguages.SK,
  });

  const [disabled, setDisabled] = useState(isNotificationExist ? false : true);
  const [isOpen, setIsOpen] = useState(false);

  const setValue = (
    key: keyof typeof values,
    value: (typeof values)[typeof key],
  ) => {
    const newValues = {
      ...values,
      [key]: key === 'trigger_datetime'
        ? moment(value).format('MM/DD/YYYY HH:mm')
        : value,
    };

    setValues(newValues);
    validateForm(newValues);
  };

  const handleCreateNotification = async () => {
    const { success, data, error } = await createNotification(getPayload());
    debugger;
    if (success) {
      notify(`Sucess: notification has created: ID ${data[0].id}`, 'success');
      onSubmit();
    } else {
      notify(error.reason);
    }
  }

  const handleEditNotification = async () => {
    const { success, data, error } = await editNotification(notification.id, getPayload());
    debugger;
    if (success) {
      notify(`Sucess: notification has edited: ID ${data.id}`, 'success');
      onSubmit();
    } else {
      notify(error.reason);
    };
  }

  const getPayload = () => {
    const copyValues = { ...values };
    if (!!copyValues.users.length) {
      copyValues.users = copyValues.users.split(' ');
      copyValues.send_to_users_with_language = '';
    } else {
      delete copyValues.users;
    };

    copyValues.trigger_datetime = new Date(copyValues.trigger_datetime);
    if (!isNotificationExist) {
      copyValues['trigger_datetimes'] = [copyValues.trigger_datetime];
      delete copyValues.trigger_datetime;
    }

    return copyValues;
  }

  const onSubmit = () => {
    redirect(SCREENS.NOTIFICATIONS);
  }

  const openDatePicker = () => {
    setIsOpen(true);
  }

  const validateForm = (newValues: Record<string, any>): void => {
    const { users, ...otherValues } = newValues;
    delete otherValues.link_after_clicking;
    const isFormValid = Object.keys(otherValues).every(key => otherValues[key]);

    setDisabled(!isFormValid);
  }

  return (
    <div className={styles.container}>
      <PageHeader
        title="Notifications"
        rightSubTitle={`${isNotificationExist ? 'Edit' : 'Add'} Notification`}
        searchDisabled={true}
        capitilize
      />

      <div className={styles.wrapper}>

        <div className={styles.content}>
          <div className={styles.row}>
            <Input
              placeholder="Headline / name *"
              defaultValue={values.title}
              onChange={(e) => setValue('title', e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <Input
              placeholder="Body text *"
              defaultValue={values.body}
              onChange={(e) => setValue('body', e.target.value)}
            />
          </div>
          <div className={styles.row}>
            <Select<Notification['type']>
              big
              onSelect={(value) => setValue('type', value)}
              value={{ value: values.type!, label: values.type! }}
              options={[
                { value: NotificationTypes.Marketing, label: NotificationTypes.Marketing },
                { value: NotificationTypes.Promo, label: NotificationTypes.Promo },
              ]}
            />
          </div>


          <div className={styles.row}>
            <Input
              placeholder="Trigger Date *"
              defaultValue={values.trigger_datetime}
              value={values.trigger_datetime}
              onChange={(e) => setValue('trigger_datetime', e.target.value)}
            />
            <DatePicker
              open={isOpen}
              selected={values.trigger_datetime}
              onChange={(date) => setValue('trigger_datetime', date)}
              onClickOutside={() => { setIsOpen(false) }}
              minDate={new Date()}
              timeInputLabel="Time:"
              showTimeInput
              className={styles.datePicker}
              popperClassName={styles.datePickerPoper} />

            <div className={styles.calendarIcon} onClick={openDatePicker}>
              <Calendar />
            </div>
          </div>
          <div className={styles.row}>
            <Input
              placeholder="Link after click"
              defaultValue={values.link_after_clicking}
              onChange={(e) => setValue('link_after_clicking', e.target.value)}
            />
          </div>

          <div className={styles.row}>
            <Select<Notification['send_to_users_with_language']>
              big
              onSelect={(value) => setValue('send_to_users_with_language', value)}
              value={{ value: values.send_to_users_with_language!, label: values.send_to_users_with_language! }}
              options={[
                { value: NotificationLanguages.SK, label: NotificationLanguages.SK },
                { value: NotificationLanguages.UA, label: NotificationLanguages.UA },
                { value: NotificationLanguages.EN, label: NotificationLanguages.EN },
              ]}
            />
          </div>

          <div className={styles.row}>
            <Input
              placeholder="Input User ID"
              defaultValue={values.users}
              onChange={(e) => setValue('users', e.target.value)}
            />
          </div>

          <div className={styles.actions}>
            <Button
              disabled={disabled}
              onClick={isNotificationExist ? handleEditNotification : handleCreateNotification}
            >
              {isNotificationExist ? 'Edit Notification' : 'Create Notification'}
            </Button>
          </div>
        </div>
        <div className={styles.preview}>
          <h3>Preview</h3>
          <div className={styles.previewBox}>
            <div className={styles.previewItem}>
              <Restrict />

              <div className={styles.previewText}>
                <h3 className={styles.previewTitle}>{values.title}</h3>
                <p className={styles.previewTitle}>{values.body}</p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(NotificationPage);
