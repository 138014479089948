export const getItem = <T = string>(
  key: StorageKeyType,
  shouldParse = false
): T | null => {
  const data: string | null = localStorage.getItem(key);

  if (!data) {
    return null;
  }

  if (shouldParse && data) {
    return JSON.parse(data) as T;
  }

  return data as T;
};

export const setItem = (key: StorageKeyType, value: string) => {
  return localStorage.setItem(key, value);
};

export const removeItem = (key: StorageKeyType) => {
  return localStorage.removeItem(key);
};
