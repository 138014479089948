import { Navigate, Route, Routes } from 'react-router-dom';

import { SCREENS } from 'constants/navigation';

import LoginPage from 'screens/Login';
import TransportPage from 'screens/Transport';
import AdminLayout from 'components/AdminLayout';
import NotFoundPage from 'screens/NotFound';
import TransportDetails from 'screens/TransportDetails';
import MapPage from 'screens/Map';
import SupportPage from 'screens/Support';
import UsersPage from 'screens/Users';
import AppConfigPage from 'screens/AppConfig';
import NotificationsPage from 'screens/Notifications';
import NotificationPage from 'screens/Notification';

const Navigation = () => {
  return (
    <Routes>
      <Route path={SCREENS.AUTH}>
        <Route index element={<Navigate replace to={SCREENS.LOGIN} />} />

        <Route path={SCREENS.LOGIN} element={<LoginPage />} />
      </Route>

      <Route path={SCREENS.APP} element={<AdminLayout />}>
        <Route index element={<Navigate replace to={SCREENS.TRANSPORTS} />} />

        <Route path={SCREENS.TRANSPORTS} element={<TransportPage />} />

        <Route path={SCREENS.ZONES} element={<MapPage />} />
        <Route path={SCREENS.SUPPORT} element={<SupportPage />} />

        <Route
          path={SCREENS.TRANSPORTS_DETAILS}
          element={<TransportDetails />}
        />

        <Route path={SCREENS.USERS} element={<UsersPage />} />
        <Route path={SCREENS.APP_CONFIG} element={<AppConfigPage />} />
        <Route path={SCREENS.NOTIFICATIONS} element={<NotificationsPage />} />
        <Route path={SCREENS.NOTIFICATION} element={<NotificationPage />} />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Navigation;
