import { useEffect, useState } from 'react';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import styles from './styles.module.css';

import Input from 'components/Input';
import Icon from 'components/Icon';

type Props = {
  title: string;
  subTitle?: string;
  rightSubTitle?: string;
  onSearch?: (text: string) => void;
  capitilize?: boolean;
  actions?: React.ReactNode;
  searchDisabled?: boolean;
  onStatsClick?: (zone: Zone) => void;
  stats?: {
    text: string;
    color: Colors;
    count: number;
    onClickKey?: any;
  }[][];
};

const PageHeader = ({
  title,
  onSearch,
  capitilize,
  actions,
  stats,
  searchDisabled = false,
  onStatsClick,
  subTitle,
  rightSubTitle
}: Props) => {
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (onSearch && search !== undefined) {
      onSearch(search);
    }
  }, [search]);

  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 500);

  return (
    <div className={styles.container}>
      <h2>
        {title}
        {rightSubTitle && <span className={styles.rightSubTitle}>{rightSubTitle}</span>}

      </h2>

      {subTitle && <h3>{subTitle}</h3>}

      {(onSearch || stats) && (
        <div className={styles.content}>
          {onSearch && (
            <div className={styles.searchContainer}>
              <Input
                capitilize={capitilize}
                icon="search-orange"
                defaultValue={search}
                onChange={onChange}
                placeholder="Search"
                className={styles.search}
                disabled={searchDisabled}
              />

              {/* <div className={styles.filter} onClick={() => setIsOpen(!isOpen)}>
            <Icon name="filter" />
            <p>Filters</p>
          </div> */}
            </div>
          )}

          {stats && (
            <div className={styles.filters}>
              <div className={styles.stats}>
                {stats.map((line, i) => {
                  if (!line.length) return null;

                  return (
                    <div className={styles.line} key={i}>
                      {line.map((data, j) => {
                        return (
                          <div
                            key={j}
                            onClick={
                              onStatsClick
                                ? () => onStatsClick(data.onClickKey)
                                : undefined
                            }
                            className={cx(
                              styles.stat,
                              data.color && styles[data.color],
                              { [styles.clickable]: !!onStatsClick },
                            )}
                          >
                            <p>
                              {data.text}: {data.count}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}

      {actions && <div className={styles.actions}>{actions}</div>}
    </div>
  );
};

export default PageHeader;
