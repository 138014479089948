import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

import Icon from 'components/Icon';

interface Props<V = string> {
  options: SelectOption<V>[];
  value?: SelectOption<V> | null;
  onSelect: (value: V) => void;
  isTop?: boolean;
  bordered?: boolean;
  big?: boolean;
  icon?: string;
  disabled?: boolean;
  className?: string;
}

const Select = <V,>({
  options,
  onSelect,
  value = null,
  isTop,
  bordered,
  big,
  icon,
  disabled,
  className,
}: Props<V>) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: SelectOption<V>) => {
    onSelect(option.value);
    setIsOpen(false);
  };

  return (
    <div
      ref={selectRef}
      className={cx(
        styles.container,
        {
          [styles.bordered]: bordered,
          [styles.big]: big,
          [styles.disabled]: disabled,
        },
        className,
      )}
    >
      {big && <div className={styles.background} />}

      <div className={styles.input} onClick={toggleDropdown}>
        {icon && <Icon name={icon} />}

        <p>{value ? value.label : 'Select an option'}</p>

        <div className={cx(styles.arrow, { [styles.up]: isOpen })} />
      </div>

      {isOpen && (
        <div className={cx(styles.options, { [styles.top]: isTop })}>
          {options.map((option) => (
            <div
              key={`${option.value}`}
              onClick={() => handleSelect(option)}
              className={cx(styles.option, {
                [styles.active]: value?.value === option.value,
              })}
            >
              <p>{option.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
