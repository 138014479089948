import { makeAutoObservable } from 'mobx';
import { ReadyState } from 'react-use-websocket';

class ChatStore {
  selectedTicket: Ticket | null = null;
  connection: ReadyState = ReadyState.CLOSED;
  tickets: Ticket[] = [];
  filterByType: TicketStatus = 'NEW';
  messages: ChatMessage[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedTicket = (ticket: Ticket | null) => {
    this.selectedTicket = ticket;
  };

  setConnectionState = (state: ReadyState) => {
    this.connection = state;
  };

  setTickets = (tickets: Ticket[]) => {
    this.tickets = tickets;
  };

  updateTicket = (id: Ticket['id'], data: Partial<Ticket>) => {
    const ticket = this.tickets.find((t) => t.id === id);

    if (ticket) {
      Object.assign(ticket, data);
    }
  };

  setFilterByType = (type: TicketStatus) => {
    this.filterByType = type;
  };

  addUpdateTicket = (ticket: Ticket) => {
    const index = this.tickets.findIndex((t) => t.id === ticket.id);

    if (index > -1) {
      this.tickets[index] = ticket;
    } else {
      this.tickets.unshift(ticket);
    }
  };

  setMessages = (messages: ChatMessage[]) => {
    this.messages = messages;
  };

  addMessage = (message: ChatMessage) => {
    this.messages.unshift(message);
  };

  reset = () => {
    this.selectedTicket = null;
    this.tickets = [];
    this.messages = [];
    this.connection = ReadyState.CLOSED;
    this.filterByType = 'NEW';
  };
}

const chatStore = new ChatStore();

export default chatStore;
