import { useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

import Icon from 'components/Icon';
import IconButton from '../IconButton';
import Button from '../Button';

type Props = {
  placeholder?: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: string;
  onSendClick?: () => void;
  defaultValue?: string;
  capitilize?: boolean;
  disabled?: boolean;
  size?: 'small' | 'big';
  value?: string;
  previews?: string[];
  onPreviewClick?: (index: number) => void;
  type?: 'text' | 'password';
};

const Input = ({
  placeholder,
  onChange,
  className,
  defaultValue = '',
  icon,
  onSendClick,
  capitilize,
  disabled,
  size,
  value,
  previews,
  onPreviewClick,
  type = 'text',
}: Props) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = capitilize ? e.target.value.toUpperCase() : e.target.value;

    setInputValue(text);
    onChange(e);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSendClick) {
      onSendClick();
    }
  };

  const hasPreviews = !!previews && previews.length > 0;

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.disabled]: disabled,
          [styles.previews]: hasPreviews,
        },
        className,
      )}
    >
      {hasPreviews && (
        <div className={styles.previews}>
          {previews?.map((uri, i) => (
            <img
              alt="preview"
              key={uri}
              src={uri}
              onClick={() => {
                onPreviewClick && onPreviewClick(i);
              }}
            />
          ))}
        </div>
      )}

      <div
        className={cx(styles.inputWrapper, {
          [styles.small]: size === 'small',
          [styles.big]: size === 'big',
        })}
      >
        {icon && <Icon name={icon} className={styles.icon} />}

        <input
          className={cx(styles.input, {
            [styles.witIcon]: !!icon,
            [styles.withSendButton]: !!onSendClick,
          })}
          type={type}
          onChange={handleChange}
          placeholder={placeholder}
          value={typeof value === 'string' ? value : inputValue}
          onKeyDown={handleKeyPress}
        />

        {onSendClick && (
          <Button
            className={styles.sendButton}
            size="small"
            inclined={false}
            onClick={onSendClick}
          >
            <Icon name="send" />
          </Button>
        )}
      </div>

      {!hasPreviews && <div className={styles.background} />}
    </div>
  );
};

export default Input;
