import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink, Outlet } from 'react-router-dom';
import cx from 'classnames';

import styles from './styles.module.css';

import authStore from 'stores/auth';
import cityStore from 'stores/city';
import Icon from 'components/Icon';
import { SCREENS } from 'constants/navigation';
import Select from 'components/Select';
import { getCityConfig } from 'api/trips';
import { updateUser } from 'api/user';
import Loader from 'components/Loader';
import notify from 'utils/notifications';
import { APP_VERSION } from 'constants/app';

type Props = {};

const AdminLayout = ({ }: Props) => {
  const { logout, profile, setProfile } = authStore;
  const { cities, selectedCity, setCityConfig, setSelectedCity } = cityStore;

  useEffect(() => {
    if (profile) {
      fetchCityConfig();
    }
  }, [profile]);

  const citiesOptions = useMemo(() => {
    return Object.values(cities ?? {}).map((city) => ({
      value: city.id,
      label: city.city_name,
    }));
  }, [cities]);

  const fetchCityConfig = async () => {
    const { success, data } = await getCityConfig();

    if (success) {
      const filteredConfig = data.filter((city) =>
        profile?.admin_userprofile.cities.includes(city.id),
      );

      setCityConfig(filteredConfig);
    }
  };

  const handleLogout = () => {
    logout();
  };

  const fullName = `${profile?.first_name} ${profile?.last_name}`;

  const onSelect = async (value: CityConfig['id']) => {
    if (value === selectedCity) return;

    const { success, data, error } = await updateUser(profile?.id!, {
      admin_userprofile: {
        ...profile?.admin_userprofile!,
        current_city: value,
      },
    });

    if (success) {
      setSelectedCity(value);
      setProfile(data);
    } else {
      notify(error.reason);
    }
  };

  const isSuperAdmin = profile?.admin_userprofile.is_superadmin;

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <div className={cx(styles.section, styles.header)}>
          <div className={styles.account}>
            <Icon name="avatar" />

            <div className={styles.user}>
              {profile ? <p className={styles.name}>{fullName}</p> : <Loader />}

              <p className={styles.position}>
                {isSuperAdmin ? 'Super Admin' : profile?.role}
              </p>
            </div>
          </div>

          <Select
            onSelect={onSelect}
            options={citiesOptions}
            icon="marker"
            value={
              selectedCity && cities
                ? { value: selectedCity, label: cities[selectedCity].city_name }
                : null
            }
          />
        </div>

        <div className={styles.navigation}>
          <div className={cx(styles.section, styles.links)}>
            <LinkItem to={SCREENS.TRANSPORTS} icon="route" text="Transport" />

            {isSuperAdmin && (
              <>
                <LinkItem
                  to={SCREENS.TRANSPORTS_DETAILS}
                  icon="scooter"
                  text="Scooter details"
                />
                <LinkItem to={SCREENS.ZONES} icon="map" text="Map" />
                <LinkItem to={SCREENS.USERS} icon="user" text="Users" />
                <LinkItem to={SCREENS.SUPPORT} icon="chat" text="Support" />
                <LinkItem
                  to={SCREENS.APP_CONFIG}
                  icon="gear"
                  text="App config"
                />
                <LinkItem
                  to={SCREENS.NOTIFICATIONS}
                  icon="ring"
                  text="Notifications"
                />
              </>
            )}
          </div>
        </div>

        <div className={styles.section}>
          <button onClick={handleLogout} className={styles.link}>
            <Icon name="logout" />

            <div className={styles.logout}>
              <p>Log out</p>
              <p>v.{APP_VERSION}</p>
            </div>
          </button>
        </div>
      </div>

      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

const LinkItem = ({
  to,
  icon,
  text,
  isSecondary,
}: {
  to: SCREENS;
  icon: string;
  text: string;
  isSecondary?: boolean;
}) => {
  return (
    <NavLink
      end
      to={to}
      className={({ isActive }) =>
        cx(styles.link, {
          [styles.active]: isActive,
          [styles.secondary]: !!isSecondary,
        })
      }
    >
      <Icon name={icon} />

      <p>{text}</p>
    </NavLink>
  );
};

export default observer(AdminLayout);
