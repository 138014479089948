import { useState, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import ChatHeader from '../ChatHeader';
import { ReactComponent as Avatar } from './icons/avatar.svg';
import styles from './styles.module.css';

import { DEFAULT_PAGINATION, PAGINATION_LIMIT } from 'constants/api';
import { getMessages, readMessages, sendMessage } from 'api/support';
import Input from 'components/Input';
import authStore from 'stores/auth';
import { formatDateTime } from 'utils/format';
import Loader from 'components/Loader';
import notify from 'utils/notifications';
import chatStore from 'stores/chat';
import Icon from 'components/Icon';

const Chat = () => {
  const { profile } = authStore;
  const {
    selectedTicket: ticket,
    updateTicket,
    messages,
    setMessages,
  } = chatStore;

  const fileRef = useRef<HTMLInputElement>(null);

  const [chatMessage, setChatMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<File[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
    limit: PAGINATION_LIMIT[4],
  });

  const filters: MessageListFilter = useMemo(() => {
    return {
      ticket: ticket?.id,
      page: pagination.page,
      limit: pagination.limit,
    };
  }, [pagination.page, pagination.limit, ticket?.id]);

  useEffect(() => {
    if (ticket) {
      setTicketRead(ticket.id);
    }
  }, [ticket?.id]);

  useEffect(() => {
    if (filters.ticket) {
      fetchMessages(filters, filters.ticket);
    } else {
      setMessages([]);
    }
  }, [filters]);

  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatMessage(e.target.value);
  };

  const setTicketRead = async (ticket: Ticket['id']) => {
    const { success, error } = await readMessages(ticket);

    if (success) {
      updateTicket(ticket, { unread_messages_count: 0 });
    }
  };

  const handleSendMessage = async () => {
    const formData = new FormData();

    formData.append('text', chatMessage);
    formData.append('ticket_id', `${ticket?.id}`);
    formData.append('recipient_id', `${ticket?.client.id}`);
    formData.append('report_id', `${ticket?.report}`);

    if (files) {
      files.forEach((file) => {
        formData.append('files', file);
      });
    }

    const { success, data, error } = await sendMessage(formData);

    if (success) {
      setMessages([data, ...messages]);
      setChatMessage('');
      setFiles([]);
    } else {
      notify(error.reason, 'error');
    }
  };

  const fetchMessages = async (
    filters: MessageListFilter,
    ticket: Ticket['id'],
  ) => {
    setMessages([]);
    setLoading(true);

    const {
      data: res,
      error,
      success,
    } = await getMessages({
      ...filters,
      ticket,
    });

    setLoading(false);

    if (success) {
      setMessages(res.results);

      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });
    }
  };

  const handleImageDelete = (i: number) => {
    const newFiles = files?.filter((_, index) => index !== i)!;

    setFiles(newFiles);
  };

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files).map((file) => file));

      e.target.value = '';
    }
  };

  const previews = useMemo(
    () => files.map((file) => URL.createObjectURL(file)),
    [files],
  );

  return (
    <div className={styles.container}>
      <ChatHeader />

      <div className={styles.messages}>
        {!loading ? (
          messages.map(({ id, text, files, author, report, created_at }, i) => {
            const isMe = author.id === profile?.id;
            const nextMesaageNotSameAuthor = messages[i - 1]?.author.id !== author.id;
            const firstMessageFromAuthor = messages[i + 1]?.author.id !== author.id;

            const hasFile = !!files && !!files.length;
            const hasReportFile = !!report && !!report.photos && !!report.photos.length;

            return (
              <div
                key={id}
                className={cx(styles.message, {
                  [styles.me]: isMe,
                  [styles.other]: !isMe,
                  [styles.last]: nextMesaageNotSameAuthor,
                  [styles.first]: firstMessageFromAuthor,
                })}
              >
                {hasFile && (
                  <div
                    className={cx(styles.files, {
                      [styles.filesWithText]: text?.length > 0,
                    })}
                  >
                    {files.map(({ file, thumbnail }, i) => (
                      <div
                        key={file}
                        className={styles.file}
                        onClick={() => window.open(file, '_blank')?.focus()}
                      >
                        {isImage(file) ? (
                          <img
                            key={i}
                            alt="photo"
                            src={thumbnail ?? file}
                            className={styles.image}
                          />
                        ) : (
                          <div className={styles.fileIcon}>
                            <Icon name="file" />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {hasReportFile && (
                  <div
                    className={cx(styles.files, {
                      [styles.filesWithText]: text?.length > 0,
                    })}
                  >
                    {report.photos.map(({ file, thumbnail }, i) => (
                      <div
                        key={file}
                        className={styles.file}
                        onClick={() => window.open(file, '_blank')?.focus()}
                      >
                        {isImage(file) ? (
                          <img
                            key={i}
                            alt="photo"
                            src={thumbnail ?? file}
                            className={styles.image}
                          />
                        ) : (
                          <div className={styles.fileIcon}>
                            <Icon name="file" />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}

                {text?.length > 0 && (
                  <div className={styles.textWrapper}>
                    {firstMessageFromAuthor && (
                      <div className={styles.avatar}>
                        <Avatar />
                      </div>
                    )}

                    <p className={styles.text}>{text}</p>

                    {nextMesaageNotSameAuthor && (
                      <p className={styles.date}>
                        {formatDateTime(created_at)}
                      </p>
                    )}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <Loader size={13} className={styles.loader} />
        )}
      </div>

      <div className={styles.input}>
        <Input
          value={chatMessage}
          onChange={onMessageChange}
          placeholder="Type ..."
          size="small"
          onSendClick={handleSendMessage}
          previews={previews}
          onPreviewClick={handleImageDelete}
        />

        <div
          className={styles.addFile}
          onClick={() => fileRef.current?.click()}
        >
          <Icon name="plus-orange" />
          <p className={styles.text}>Add file</p>

          <input
            multiple
            id="upload"
            ref={fileRef}
            hidden
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </div>
      </div>
    </div>
  );
};

const isImage = (fileName: string) => {
  return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(fileName);
};

export default observer(Chat);
