import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const login = async (email: string, password: string) => {
  logger('LOGIN::', email, password);

  return fetch<{ token: string }, { reason: string }>(
    'LOGIN',
    {
      method: 'POST',
      data: { email, password },
    },
    false,
  );
};
