import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { logger } from './logger';
import { getItem } from './storage';

import { API_URL, PATHS } from 'constants/api';
import authStore from 'stores/auth';

export const fetch = async <T = unknown, E = ApiResponse['error']>(
  path: PATH,
  options: AxiosRequestConfig = {},
  isAuthorised = true,
  pathParams?: Record<string, string | number>,
): Promise<ApiResponse<T, E>> => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
    ...options.headers,
  };

  if (isAuthorised) {
    headers['Authorization'] = getItem('AUTH');
  }

  try {
    const res = await axios({
      ...options,
      headers,
      url: getFullPath(path, pathParams),
      method: options.method || 'GET',
    });

    return {
      success: true,
      data: res.data,
      statusCode: res.status,
      error: null,
    };
  } catch (error) {
    const { response: errResponse } = error as AxiosError;

    const { status, data: err } = errResponse || {};

    logger(`API_ERROR [${path}]::`, `[${status}]`, err);

    if (status === 401) {
      authStore.logout();

      return {
        success: false,
        statusCode: status,
        data: null,
        error: { reason: 'Unauthorized' } as E,
      };
    }

    return {
      success: false,
      statusCode: status || null,
      data: null,
      error: (err as E) ?? (error as E),
    };
  }
};

const getFullPath = (
  path: PATH,
  pathParams?: Record<string, string | number>,
) => {
  const url = `${API_URL}${PATHS[path]}`;

  if (!pathParams) {
    return url;
  }

  const pathWithParams = Object.keys(pathParams).reduce((acc, key) => {
    return acc.replace(`:${key}`, pathParams[key].toString());
  }, url);

  return pathWithParams;
};

export const externalFetch = async <T = unknown, E = ApiResponse['error']>(
  url: string,
  options: AxiosRequestConfig = {},
): Promise<ApiResponse<T, E>> => {
  try {
    const res = await axios({
      ...options,
      url,
      method: options.method || 'GET',
    });

    return {
      success: true,
      statusCode: res.status,
      data: res.data ?? null,
      error: null,
    };
  } catch (error) {
    logger(`API_ERROR [${url}]::`, error);

    const { response: errResponse } = error as AxiosError;
    const { status, data: err } = errResponse || {};

    return {
      success: false,
      statusCode: status || null,
      data: null,
      error: err as E,
    };
  }
};
