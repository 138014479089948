export enum SCREENS {
  // Auth root
  AUTH = '/auth',
  // Auth pages
  LOGIN = '/auth/login',

  // App root
  APP = '/',
  // Transports
  TRANSPORTS = '/transport',
  TRANSPORTS_DETAILS = '/transport-details',
  // Zones
  ZONES = '/zones',
  // Support
  SUPPORT = '/support',
  // Users
  USERS = '/users',
  // Remote config
  APP_CONFIG = '/app-config',
  // Notification
  NOTIFICATIONS = '/notifications',
  NOTIFICATION = '/notification',
}
