import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ReadyState } from 'react-use-websocket';
import cx from 'classnames';

import styles from './styles.module.css';

import Button from 'components/Button';
import chatStore from 'stores/chat';
import { updateTicket as changeTicketStatus } from 'api/support';
import notify from 'utils/notifications';
import Loader from 'components/Loader';

const ChatHeader = () => {
  const { connection, selectedTicket: ticket, updateTicket } = chatStore;

  const [loading, setLoading] = useState(false);

  const onTicketClose = async () => {
    if (!ticket) return;

    setLoading(true);

    const { error, success, data } = await changeTicketStatus(ticket?.id, {
      type: 'RESOLVED',
    });

    setLoading(false);

    if (success) {
      notify('Ticket resolved', 'success');
      updateTicket(ticket?.id, data);
    } else {
      notify(error.reason, 'error');
    }
  };

  const status = getConnectionName(connection);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.leftSide}>
          <h3>
            {ticket?.client.first_name} {ticket?.client.last_name} (ID{' '}
            {ticket?.client.id})
          </h3>

          <div className={styles.connection}>
            <span
              className={cx(styles.indicator, {
                [styles.green]: connection === ReadyState.OPEN,
                [styles.yellow]: connection === ReadyState.CONNECTING,
              })}
            />

            <p> {status}</p>
          </div>
        </div>

        <Button
          rounded
          inclined={false}
          style="secondary-red"
          size="small"
          icon={!loading ? 'delete' : undefined}
          onClick={onTicketClose}
          disabled={loading}
        >
          {loading ? <Loader size={6} /> : 'Close ticket'}
        </Button>
      </div>
    </div>
  );
};

const getConnectionName = (connection: ReadyState) => {
  switch (connection) {
    case ReadyState.CLOSED:
      return 'Offline';
    case ReadyState.CONNECTING:
      return 'Connecting';
    case ReadyState.OPEN:
      return 'Online';
    case ReadyState.CLOSING:
      return 'Offline';
    default:
      return 'Offline';
  }
};

export default observer(ChatHeader);
