import 'react-toastify/dist/ReactToastify.css';
import 'reset-css';
import './styles/variables.css';
import './styles/typography.css';
import './styles/global.css';
import "react-datepicker/dist/react-datepicker.css";
import { Bounce, ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom/client';
import { APIProvider } from '@vis.gl/react-google-maps';
import { BrowserRouter } from 'react-router-dom';

import AuthProvider from 'providers/AuthProvider';
import Navigation from 'navigation/Navigation';
import { GOOGLE_MAPS_API_KEY } from 'constants/map';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
    <BrowserRouter>
      <AuthProvider>
        <Navigation />

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
          limit={8}
        />
      </AuthProvider>
    </BrowserRouter>
  </APIProvider>,
);
