import cx from 'classnames';

import Loader from 'components/Loader';

import styles from './styles.module.css';
import Icon from '../Icon';

type Props = {
  children: string | React.ReactNode;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  style?: 'secondary' | 'secondary-red';
  size?: 'small';
  icon?: string;
  inclined?: boolean;
  rounded?: boolean;
};

const Button = ({
  children,
  onClick,
  className,
  loading = false,
  disabled = false,
  style,
  size,
  icon,
  inclined = true,
  rounded = false,
}: Props) => {
  const isDisabled = loading || disabled;

  return (
    <button
      className={cx(
        styles.container,
        {
          [styles.inclined]: inclined,
          [styles.disabled]: isDisabled,
          [styles.secondary]: style?.includes('secondary'),
          [styles.red]: style?.includes('red'),
          [styles.small]: size === 'small',
          [styles.rounded]: rounded,
        },
        className,
      )}
      onClick={onClick}
      disabled={isDisabled}
    >
      {!loading && icon && <Icon className={styles.icon} name={icon} />}
      {loading && <Loader />}

      {!loading &&
        (typeof children === 'string' ? <p>{children}</p> : children)}
    </button>
  );
};

export default Button;
