import { useState } from 'react';

import styles from './styles.module.css';

import { addScooter, updateScooter } from 'api/transport';
import notify from 'utils/notifications';
import Select from 'components/Select';
import Button from 'components/Button';
import Input from 'components/Input';
import Modal from 'components/Modal';

type Props = {
  title: string;
  setIsOpen: () => void;
  onSubmit: (scooter: Scooter) => void;
  initialValues?: Scooter | null;
  city_id: Scooter['city_id'];
};

const ScooterInfoModal = ({
  setIsOpen,
  onSubmit,
  initialValues,
  title,
  city_id,
}: Props) => {
  const [values, setValues] = useState<Partial<Scooter>>({
    type: initialValues?.type || 'New',
    id: initialValues?.id || '',
    qr_code: initialValues?.qr_code || '',
    frame_number: initialValues?.frame_number || '',
    iccid: initialValues?.iccid || '',
    bms: initialValues?.bms || '',
    motor_series_number: initialValues?.motor_series_number || '',
    battery: initialValues?.battery || 100,
    city_id: initialValues?.city_id ?? city_id,
  });

  const setValue = (
    key: keyof typeof values,
    value: (typeof values)[typeof key],
  ) => {
    setValues((prev) => ({ ...prev, [key]: value }));
  };

  const handleScooterChange = async () => {
    const { success, data, error } = await updateScooter(
      initialValues?.id!,
      values,
    );

    if (success) {
      notify(`Sucess: updated: SK-${data.id}`, 'success');

      onSubmit(data);
      setIsOpen();
    } else {
      notify(error.reason);
    }
  };

  const handleSubmitAdd = async () => {
    const { success, data, error } = await addScooter(values);

    if (success) {
      notify(`Sucess: new scooter added: SK-${data.id}`, 'success');

      onSubmit(data);
      setIsOpen();
    } else {
      notify(error.reason);
    }
  };

  return (
    <Modal
      isOpen
      title={title}
      icon="plus-orange"
      onClose={setIsOpen}
      actions={
        <>
          <Button
            onClick={initialValues ? handleScooterChange : handleSubmitAdd}
          >
            Save
          </Button>

          <Button style="secondary-red" onClick={setIsOpen}>
            Cancel
          </Button>
        </>
      }
    >
      <div className={styles.rows}>
        <div className={styles.row}>
          <Select<Scooter['type']>
            big
            onSelect={(value) => setValue('type', value)}
            value={{ value: values.type!, label: values.type! }}
            options={[
              { value: 'Old', label: 'Old' },
              { value: 'New', label: 'New' },
            ]}
          />
        </div>

        <div className={styles.row}>
          <Input
            placeholder="ID"
            defaultValue={values.id}
            onChange={(e) => setValue('id', e.target.value)}
          />

          <Input
            placeholder="QR code"
            defaultValue={values.qr_code}
            onChange={(e) => setValue('id', e.target.value)}
          />
        </div>

        <div className={styles.row}>
          <Input
            placeholder="Frame no."
            defaultValue={values.frame_number}
            onChange={(e) => setValue('frame_number', e.target.value)}
          />

          <Input
            placeholder="iccid (SIM)"
            defaultValue={values.iccid}
            onChange={(e) => setValue('iccid', e.target.value)}
          />
        </div>

        <div className={styles.row}>
          <Input
            placeholder="BMS"
            defaultValue={values.bms}
            onChange={(e) => setValue('bms', e.target.value)}
          />

          <Input
            placeholder="Motor series number"
            defaultValue={values.motor_series_number}
            onChange={(e) => setValue('motor_series_number', e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ScooterInfoModal;
