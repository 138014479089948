import { memo, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.css';
import Action from 'components/Action';
import PageHeader from 'components/PageHeader';
import Table, { TableCell, TableHeadCell, TableRow } from 'components/Table';
import { DEFAULT_PAGINATION, PAGINATION_LIMIT } from 'constants/api';
import Checkbox from 'components/Checkbox';
import { acitveNotification, deacitveNotification, getNotifications } from 'api/notifications';

import { ReactComponent as Active } from './icons/active.svg';
import { ReactComponent as Inactive } from './icons/inactive.svg';
import { useNavigate } from 'react-router-dom';
import { SCREENS } from 'constants/navigation';
import notify from 'utils/notifications';

type Id = Notification['id'];

const NotificationsPage = () => {
  const redirect = useNavigate();
  const [search, setSearch] = useState('');
  const [data, setData] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [selected, setSelected] = useState<Notification['id'][]>([]);
  const [order, setOrder] = useState<NotificationsListFilters['ordering']>('title');
  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
    limit: PAGINATION_LIMIT[1],
  });

  const filters = useMemo(() => {
    return {
      search,
      page: pagination.page,
      limit: pagination.limit,
      ordering: order,
    };
  }, [search, pagination.page, pagination.limit, order]);

  useEffect(() => {
    if (filters) {
      fetchNotifications(filters);
    }
  }, [filters]);


  const fetchNotifications = async (filters: NotificationsListFilters) => {
    setSelected([]);
    setLoading(true);

    const { data: res, success } = await getNotifications(filters);

    setLoading(false);

    if (success) {
      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });

      setData(res.results);
    } else {
      setData([]);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ ...DEFAULT_PAGINATION });
    setData([]);
  };

  const handleRowClick = (id: Id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelected([]);
    } else {
      setSelected(data.map(({ id }) => id));
    }
  };

  const orderClick = (field: keyof Notification) => {
    setPagination({ ...DEFAULT_PAGINATION });
    setOrder(field);
  };

  const redirectToNotification = (notification: Notification | any) => {
    redirect(SCREENS.NOTIFICATION, { state: { notification } });
  }

  // const deleteNotification = () => {

  // }


  const handleCheckboxChange = (id: Notification['id']) => {
    setData(data.map(notification =>
      notification.id === id ? {
        ...notification,
        is_active: !notification.is_active,
      } : notification
    ));
  }

  const activateNotification = async () => {

    const { success, data, error } = await acitveNotification(selected[0]);
    if (success) {
      notify(`Sucess: new notification activated`, 'success');
      onSubmit();
    } else {
      notify(error.reason);
    };
  }

  const turnOffNotification = async () => {

    const { success, data, error } = await deacitveNotification(selected[0]);
    if (success) {
      notify(`Sucess: notification turned off`, 'success');
      onSubmit();
    } else {
      notify(error.reason);
    };
  }

  const onSubmit = () => {
    fetchNotifications(filters);
  }

  const selectedAll = !!data.length && selected.length === data.length;
  const actionsDisabled = selected.length !== 1 || selectedLoading;

  return (
    <div className={styles.container}>
      <PageHeader
        title="Notifications"
        onSearch={handleSearch}
        searchDisabled={loading || selectedLoading}
        capitilize
        actions={
          <>
            <Action
              text="Create new notification"
              icon="plus-orange"
              disabled={selectedLoading || !actionsDisabled}
              onClick={() => redirectToNotification({})}
            />

            <Action
              text="Edit Notification"
              icon="pencil"
              disabled={actionsDisabled}
              onClick={() => redirectToNotification(data.find(({ id }) => id === selected[0]))}
            />

            <Action
              text="Activate"
              icon="plus-orange"
              disabled={actionsDisabled}
              onClick={activateNotification}
            />

            <Action
              text="Turn Off"
              icon="minus-orange"
              disabled={actionsDisabled}
              onClick={turnOffNotification} />
          </>
        }
      />

      <div className={styles.content}>
        <Table
          pagination={pagination}
          loading={loading}
          onPaginationChange={(data: Pagination) =>
            setPagination({ ...pagination, ...data })
          }
          header={
            <TableRow>
              <TableHeadCell onClick={handleSelectAll}>
                <Checkbox checked={selectedAll} />
              </TableHeadCell>

              {HEADER_CONFIG.map(({ title, field, center }) => {
                const isOrdering = order.includes(field)
                  ? order.includes('-')
                    ? 'asc'
                    : 'desc'
                  : false;

                return (
                  <TableHeadCell
                    key={field}
                    center={center}
                    order={isOrdering}
                    onClick={() => orderClick(field)}
                  >
                    {title}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          }
        >
          {data.map(
            ({ id, title, type, trigger_datetime, is_active }) => (
              <TableRow
                key={`${id}_${title}`}
                disabled={selectedLoading && selected.includes(id)}
                selected={selected.includes(id)}
                onClick={() => handleRowClick(id)}
              >
                <TableCell>
                  <Checkbox checked={selected.includes(id)} />
                </TableCell>

                <TableCell className={styles.orangeColor}>
                  ID {id}
                </TableCell>

                <TableCell>{title}</TableCell>
                <TableCell>{type}</TableCell>
                <TableCell>{trigger_datetime}</TableCell>
                <TableCell>
                  {is_active ?
                    <>
                      <Active />
                      <span className={styles.statusText}>Active</span>
                    </> :
                    <>
                      <Inactive />
                      <span className={styles.statusText}>Inactive</span>
                    </>}
                </TableCell>

              </TableRow>
            ),
          )}
        </Table>
      </div>
    </div>
  );
};

const ACTIONS = {
} as const;

const HEADER_CONFIG = [
  { title: 'Notification Id', field: 'id', center: false },
  { title: 'Name', field: 'title', center: false },
  { title: 'Type', field: 'type', center: false },
  { title: 'Trigger Date Time', field: 'trigger_datetime', center: false },
  { title: 'Status', field: 'is_active', center: false },
] as const;

export default memo(NotificationsPage);
