import cx from 'classnames';
import { Map as MapComponent, MapProps } from '@vis.gl/react-google-maps';
import { observer } from 'mobx-react-lite';

import styles from './styles.module.css';
import Controls from './components/Controls';

import { CITIES, DEFAULT_ZOOM, MAP_ID, MAX_ZOOM } from 'constants/map';
import cityStore from 'stores/city';

type Props = MapProps & {
  className?: string;
  children?: React.ReactNode;
  controls?: {
    icon: string;
    onClick: () => void;
    disabled?: boolean;
    active?: boolean;
  }[];
  title?: string;
  infoWindow?: React.ReactNode;
};

const Map = ({
  className,
  children,
  controls,
  title,
  infoWindow,
  ...mapProps
}: Props) => {
  const { selectedCity } = cityStore;

  return (
    <div className={cx(styles.container, className)}>
      {controls && <Controls actions={controls} />}

      {title && <p className={styles.title}>{title}</p>}

      {infoWindow && <div className={styles.infoWindow}>{infoWindow}</div>}

      <MapComponent
        {...mapProps}
        defaultZoom={DEFAULT_ZOOM}
        mapId={MAP_ID}
        maxZoom={MAX_ZOOM}
        defaultCenter={CITIES[selectedCity!]}
      >
        {children}
      </MapComponent>
    </div>
  );
};

export default observer(Map);
