import { ReactComponent as Sad } from './icons/sad.svg';
import styles from './styles.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      <h2>404 - Page not found</h2>

      <Sad />
    </div>
  );
};

export default NotFoundPage;
