import { useEffect, useMemo, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

import styles from './styles.module.css';

import { getScooterErrors, getScooterTrips } from 'api/transport';
import SideBarInfo, { SideBarTable } from 'components/SideBarInfo';
import { formatDuration, formatDateTime, mapCurrency } from 'utils/format';
import Map from 'components/Map';
import ScooterMarker from 'components/Map/components/ScooterMarker';
import DotMarker from 'components/Map/components/DotMarker';
import Action from 'components/Action';
import { ACTIONS } from 'constants/scooter';

type Props = {
  selectedScooter: Scooter | null;
  onClose: (data?: null) => void;
  onActionClick: (
    action: keyof typeof ACTIONS,
    ids?: Scooter['id'][],
  ) => () => Promise<void>;
};

const TransportSideBar = ({
  onClose,
  selectedScooter,
  onActionClick,
}: Props) => {
  const [errors, setErrors] = useState<ScooterError[]>([]);
  const [trips, setTrips] = useState<ScooterTripsInfo[]>([]);
  const map = useMap();

  const markers = useMemo(() => {
    if (!global.google || !selectedScooter?.geo_point) {
      return { markers: [], bounds: null, center: null };
    }

    const bounds = new google.maps.LatLngBounds();
    const markers: {
      id: string | number;
      label: string;
      position: google.maps.LatLngLiteral;
    }[] = [];

    bounds.extend(
      new google.maps.LatLng(
        selectedScooter.geo_point.latitude,
        selectedScooter.geo_point.longitude,
      ),
    );

    trips.forEach(({ finish_geo_point, datetime_finish, id }) => {
      bounds.extend(
        new google.maps.LatLng(
          finish_geo_point.latitude,
          finish_geo_point.longitude,
        ),
      );

      markers.push({
        id,
        label: formatDateTime(datetime_finish),
        position: {
          lat: finish_geo_point.latitude,
          lng: finish_geo_point.longitude,
        },
      });
    });

    return { markers, bounds, center: bounds.getCenter().toJSON() };
  }, [trips, selectedScooter?.geo_point]);

  useEffect(() => {
    if (map && markers.bounds) {
      map.fitBounds(markers.bounds);
    }
  }, [markers.bounds]);

  useEffect(() => {
    if (selectedScooter?.id) {
      fetchScooterErrors(selectedScooter.id);
      fetchScooterTrips(selectedScooter.id);
    }
  }, [selectedScooter?.id]);

  const fetchScooterErrors = async (id: Scooter['id']) => {
    const { data, success } = await getScooterErrors(id);

    if (success) {
      setErrors(data.results);
    } else {
      setErrors([]);
    }
  };

  const fetchScooterTrips = async (id: Scooter['id']) => {
    const { data, success } = await getScooterTrips(id);

    if (success) {
      setTrips(data.results);
    } else {
      setTrips([]);
    }
  };

  return (
    <SideBarInfo
      onClose={() => onClose(null)}
      isOpen={!!selectedScooter}
      title={`${selectedScooter?.qr_code ?? ''}`}
      titleBadges={[
        {
          text: selectedScooter?.type ?? 'N/A',
          color: 'blue',
        },
        {
          text: selectedScooter?.priority_status ?? 'N/A',
          color: selectedScooter?.priority_status === 'High' ? 'red' : 'green',
        },
      ]}
    >
      <div className={styles.actionContainer}>
        <Action
          withBorder
          className={styles.action}
          text="Put in work"
          icon="put-in"
          onClick={onActionClick('work', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Put out work"
          icon="put-out"
          onClick={onActionClick('outWork', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Unlock"
          icon="lock"
          onClick={onActionClick('unlock', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Lock"
          icon="lock-closed"
          onClick={onActionClick('lock', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Open battery"
          icon="open"
          onClick={onActionClick('battery', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Restart"
          icon="reload"
          onClick={onActionClick('reload', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Alarm"
          icon="bell"
          onClick={onActionClick('signal', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Update location"
          icon="map"
          onClick={onActionClick('location', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />

        <Action
          withBorder
          className={styles.action}
          text="Warehouse"
          icon="house"
          onClick={onActionClick('wearHouse', [selectedScooter?.id!])}
          disabled={!selectedScooter}
        />
      </div>

      <SideBarTable
        title={{
          text: 'Scooter errors',
          color: 'red',
          icon: 'warning-red',
        }}
        headers={['Scooter', 'Damage', 'Status', 'Estimated time']}
        rows={errors.map((error) => [
          error.scooter,
          error.error,
          error.status,
          error.estimated_time ? formatDuration(error.estimated_time) : 'N/A',
        ])}
      />

      {selectedScooter && (
        <SideBarTable
          title={{ text: 'Activity of scooter' }}
          headers={['Status', 'Battery', 'Last user']}
          rows={[
            [
              selectedScooter.work_status,
              `${selectedScooter.battery}%` || 'N/A',
              selectedScooter.last_user
                ? `ID ${selectedScooter.last_user} `
                : 'N/A',
            ],
          ]}
        />
      )}

      {selectedScooter?.geo_point && (
        <Map className={styles.map}>
          {selectedScooter && (
            <ScooterMarker
              lat={selectedScooter.geo_point?.latitude!}
              lng={selectedScooter.geo_point?.longitude!}
              battery={selectedScooter.battery}
            />
          )}

          {markers.markers.map(({ label, position, id }, i) => (
            <DotMarker
              key={id}
              zIndex={i}
              title={label}
              lat={position.lat}
              lng={position.lng}
            />
          ))}
        </Map>
      )}

      <SideBarTable
        title={{ text: 'Last rides' }}
        headers={['Ride', 'Date finish', 'User', 'Status', 'Cost']}
        rows={trips.map((trip) => [
          trip.id,
          formatDateTime(trip.datetime_finish),
          `ID ${trip.client}`,
          trip.status,
          `${mapCurrency[trip.currency]} ${trip.total_cost}`,
        ])}
      />
    </SideBarInfo>
  );
};

export default TransportSideBar;
