import { memo, useEffect, useState } from 'react';
import { useMap } from '@vis.gl/react-google-maps';

type Props = google.maps.PolygonOptions & {
  id?: Zone['id'];
  onClick?: (id?: Zone['id']) => void;
  onChange?: (coordinates: google.maps.LatLngLiteral[]) => void;
};

const Polygon = ({ onClick, id, onChange, ...props }: Props) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const polygon = new google.maps.Polygon(props);

    polygon.setMap(map);

    if (onClick) {
      google.maps.event.addListener(polygon, 'click', () => {
        onClick(id);
      });
    }

    if (onChange) {
      polygon.addListener('mouseup', (e: any) => {
        const coordinates = polygon
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

        onChange([...coordinates]);
      });
    }

    return () => {
      polygon.setMap(null);

      google.maps.event.clearInstanceListeners(polygon);
    };
  }, [map, props.paths]);

  return null;
};

export default memo(Polygon);
