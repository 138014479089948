import { useCallback, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getCenterOfBounds } from 'geolib';
import { MapMouseEvent, useMap } from '@vis.gl/react-google-maps';

import styles from './styles.module.css';

import PageHeader from 'components/PageHeader';
import Map from 'components/Map';
import {
  CITIES,
  DEFAULT_ZOOM,
  EDIT_ZONE_COLOR,
  ZONES,
  ZONES_COLORS,
} from 'constants/map';
import cityStore from 'stores/city';
import { getZones } from 'api/zones';
import Table, { TableRow, TableHeadCell, TableCell } from 'components/Table';
import { DEFAULT_PAGINATION } from 'constants/api';
import { getCapitalizedText } from 'utils/format';
import ZoneMarker from 'components/Map/components/ZoneMarker';
import Polygon from 'components/Map/components/Polygon';
import ZoneInfoModal from './components/ZoneInfoModal';
import { convertLatLngToGeoPoint } from 'utils/map';

const DEFAULT_ZONE_DATA: ZoneForm = {
  zone_rules: ZONES.PARKING,
  status: 'CURRENT',
  name: '',
  polygon: [],
  city: 0,
};

const MapPage = () => {
  const map = useMap();

  // edit mode polygon
  const [polygon, setPolygon] = useState<LatLngLiteral[]>([]);
  // save zone data popup
  const [infoModal, setInfoModal] = useState<ZoneForm | null>(null);
  const [mode, setMode] = useState<'add' | null>(null);
  const [loading, setLoading] = useState(false);
  const [zonesVisible, setZonesVisible] = useState(true);
  const [zones, setZones] = useState<Zone<LatLngLiteral>[]>([]);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState<ZonesListFilters['ordering']>('id');
  const [selected, setSelected] = useState<Zone<LatLngLiteral> | null>(null);

  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
  });

  const { selectedCity } = cityStore;

  const isAddMode = mode === 'add';
  const isEditMode = !!selected;

  const filters = useMemo(() => {
    if (!selectedCity) return null;

    return {
      city_id: selectedCity,
      name: search,
      // status: 'CURRENT',
      // zone_rules: 1,
      page: pagination.page,
      page_size: pagination.limit,
      ordering: order,
    } as ZonesListFilters;
  }, [selectedCity, pagination.page, pagination.limit, order, search]);

  useEffect(() => {
    if (map && selectedCity) {
      map.setCenter(CITIES[selectedCity]);
      map.setZoom(DEFAULT_ZOOM);
    }
  }, [pagination, selectedCity]);

  useEffect(() => {
    setPolygon(selected ? selected.polygon : []);
  }, [selected]);

  useEffect(() => {
    if (map && selected) {
      const bounds = new google.maps.LatLngBounds();

      selected.polygon.forEach(({ lat, lng }) => {
        bounds.extend(new google.maps.LatLng(lat, lng));
      });

      map.fitBounds(bounds);
    }
  }, [selected, map]);

  useEffect(() => {
    if (filters && selectedCity) {
      fetchZones(filters);
    }
  }, [filters, selectedCity]);

  const fetchZones = async (filters: ZonesListFilters) => {
    setSelected(null);
    setLoading(true);

    const { data: res, success } = await getZones(filters);

    setLoading(false);

    if (success) {
      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });

      setZones(
        res.results.map((zone) => ({
          ...zone,
          polygon: convertLatLngToGeoPoint(zone.polygon),
        })),
      );
    } else {
      setZones([]);
    }
  };

  const orderClick = (field: keyof Zone) => {
    setPagination({ ...DEFAULT_PAGINATION });

    if (field === order) {
      setOrder(`-${field}`);
    } else {
      setOrder(field);
    }
  };

  const handleSearch = (value: string) => {
    setZones([]);
    setSearch(value);
    setPagination({ ...DEFAULT_PAGINATION });
  };

  const handleSelect = (zone: Zone<LatLngLiteral>) => {
    setZonesVisible(true);
    setSelected(zone);
  };

  const handleMapClick = (e: MapMouseEvent) => {
    e.stop();

    if (!isAddMode) return;

    const latLng = e.detail.latLng;

    if (latLng) {
      setPolygon((prev) => [...prev, latLng]);
    }
  };

  const handleSetMode = (
    _mode: typeof mode,
    polygon: Zone<LatLngLiteral>['polygon'] = [],
  ) => {
    setMode(_mode);
    setPolygon(polygon);

    if (_mode === null) {
      setSelected(null);
    }
  };

  const handleSave = () => {
    if (!selectedCity) return;

    if (isAddMode) {
      const data: ZoneForm = {
        ...DEFAULT_ZONE_DATA,
        polygon,
        city: selectedCity,
      };

      setInfoModal(data);
    } else if (isEditMode) {
      setInfoModal(getFormData({ ...selected!, polygon }, selectedCity));
    }
  };

  const handleSetData = (data: Zone<LatLngLiteral>) => {
    setZones((prev) => {
      const index = prev.findIndex((zone) => zone.id === data.id);

      if (index === -1) {
        return [data, ...prev];
      }

      prev[index] = data;

      return [...prev];
    });

    handleSetMode(null);
  };

  const handleClickZone = useCallback((zone: Zone<LatLngLiteral>) => {
    setSelected(zone);

    const zoneRow = document.getElementById(`zone-${zone.id}`);

    if (zoneRow) {
      zoneRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const handleUndo = () => {
    setPolygon((prev) => prev.slice(0, prev.length - 1));
  };

  const controls = useMemo(() => {
    if (mode === 'add' || isEditMode) {
      return [
        {
          icon: 'close',
          onClick: () => handleSetMode(null, []),
        },
        {
          icon: !zonesVisible ? 'eye' : 'eye-crossed',
          onClick: () => setZonesVisible((prev) => !prev),
          active: zonesVisible,
        },
        {
          icon: 'reload',
          onClick: handleUndo,
        },
        {
          icon: 'delete',
          onClick: () => setPolygon([]),
        },
        {
          icon: 'save',
          onClick: handleSave,
        },
      ];
    }

    if (mode === null) {
      return [
        {
          icon: 'plus',
          onClick: () => handleSetMode('add'),
        },
        {
          icon: !zonesVisible ? 'eye' : 'eye-crossed',
          onClick: () => setZonesVisible((prev) => !prev),
          active: zonesVisible,
        },
      ];
    }
  }, [mode, isEditMode, zonesVisible, polygon]);

  return (
    <div className={styles.container}>
      <PageHeader title="Zones" onSearch={handleSearch} />

      <div className={styles.content}>
        <Table
          pagination={pagination}
          className={styles.table}
          loading={loading}
          onPaginationChange={(data: Pagination) =>
            setPagination({ ...pagination, ...data })
          }
          header={
            <TableRow>
              {HEADER_CONFIG.map(({ title, field, center }) => {
                const isOrdering = order.includes(field)
                  ? order.includes('-')
                    ? 'asc'
                    : 'desc'
                  : false;

                return (
                  <TableHeadCell
                    key={field}
                    center={center}
                    order={isOrdering}
                    onClick={() => orderClick(field)}
                  >
                    {title}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          }
        >
          {zones.map((zone) => {
            const { id, status, zone_rules, name } = zone;

            return (
              <TableRow
                id={`zone-${id}`}
                key={id}
                selected={selected?.id === id}
                onClick={() => handleSelect(zone)}
              >
                <TableCell className={styles.orangeColor}>{id}</TableCell>

                <TableCell>{status}</TableCell>

                <TableCell>{getCapitalizedText(zone_rules)}</TableCell>
                <TableCell>{name}</TableCell>
              </TableRow>
            );
          })}
        </Table>

        {selectedCity && (
          <Map
            maxZoom={20}
            className={styles.map}
            defaultCenter={CITIES[selectedCity]}
            onClick={handleMapClick}
            controls={controls}
          >
            {(isAddMode || isEditMode) && (
              <>
                <Polygon
                  editable
                  draggable
                  onChange={setPolygon}
                  zIndex={10000}
                  paths={polygon}
                  fillColor={EDIT_ZONE_COLOR}
                  strokeColor={EDIT_ZONE_COLOR}
                  strokeOpacity={1.0}
                  fillOpacity={0.4}
                  strokeWeight={1}
                />
              </>
            )}

            {zonesVisible &&
              zones.map((zone, i) => {
                const { id, polygon, zone_rules } = zone;
                const markerCenter = getCenterOfBounds(polygon);

                return (
                  <div key={id}>
                    <Polygon
                      clickable
                      id={id}
                      zIndex={i}
                      paths={polygon}
                      fillColor={ZONES_COLORS[zone_rules]}
                      strokeColor={ZONES_COLORS[zone_rules]}
                      strokeOpacity={1.0}
                      fillOpacity={0.4}
                      strokeWeight={1}
                      onClick={() => handleClickZone(zone)}
                    />

                    <ZoneMarker
                      zIndex={i}
                      onClick={() => handleClickZone(zone)}
                      icon={zone_rules}
                      coordinates={{
                        lat: markerCenter.latitude,
                        lng: markerCenter.longitude,
                      }}
                    />
                  </div>
                );
              })}
          </Map>
        )}
      </div>

      {selectedCity && !!infoModal && (
        <ZoneInfoModal
          title={infoModal ? 'Update zone' : 'New zone'}
          setIsOpen={setInfoModal}
          data={infoModal}
          city={selectedCity}
          setData={handleSetData}
        />
      )}
    </div>
  );
};

const HEADER_CONFIG = [
  { title: 'Zone', field: 'id', center: false },
  { title: 'Status', field: 'status', center: false },
  { title: 'Type', field: 'zone_rules', center: false },
  { title: 'Name', field: 'name', center: false },
] as const;

const getFormData = (
  zone: Zone<LatLngLiteral>,
  city: Zone['city'],
): ZoneForm => ({
  city,
  id: zone.id,
  name: zone.name,
  status: zone.status,
  zone_rules: zone.zone_rules,
  polygon: zone.polygon,
});

export default observer(MapPage);
