import { ChangeEvent, HTMLProps } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

export const DefaultInput = ({
  value,
  onChange,
  style,
  type = 'text',
}: {
  value: string;
  onChange: (data: string) => void;
  style?: string;
  type?: 'text' | 'number';
}) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <input
      type={type}
      onChange={handleChange}
      value={value}
      className={cx(styles.input, style)}
    />
  );
};

export const DefaultSelect = ({
  value,
  onChange,
  children,
  style,
}: {
  value: string;
  onChange: (data: string) => void;
  style?: string;
  children: React.ReactNode;
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(event.target.value as any);
  };

  return (
    <select
      value={value}
      className={cx(styles.select, style)}
      onChange={handleChange}
    >
      {children}
    </select>
  );
};
