import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import {
  CHAT_SOCKETS_MESSAGES,
  SOCKETS_URLS,
  getSocketOption,
} from 'constants/ws';
import { logger } from 'utils/logger';
import { parseJSON } from 'utils/parse';
import authStore from 'stores/auth';
import chatStore from 'stores/chat';

const ChatSocketsProvider = () => {
  const { setConnectionState, addMessage, selectedTicket } = chatStore;
  const { token } = authStore;

  const { lastMessage: message, readyState } = useWebSocket<
    valueof<ChatSocketsDataType>
  >(SOCKETS_URLS.CHAT, getSocketOption(token!), true);

  useEffect(() => {
    const data = message
      ? parseJSON<valueof<ChatSocketsDataType>>(message.data)
      : null;

    if (!data) return;

    logger('SOCKETS_MESSAGE::', message);

    chatSocketActions(data);
  }, [message]);

  useEffect(() => {
    logger('CHAT_SOCKETS_STATE::', readyState);

    setConnectionState(readyState);
  }, [readyState]);

  const handleNewMessage = (data: ChatMessage) => {
    if (selectedTicket && data.ticket === selectedTicket.id) {
      addMessage(data);
    }
  };

  const chatSocketActions = (data: valueof<ChatSocketsDataType>) => {
    switch (data.message) {
      case CHAT_SOCKETS_MESSAGES.NEW_MESSAGE:
        handleNewMessage(data.payload.data);
        break;
      case CHAT_SOCKETS_MESSAGES.UPDATED_MESSAGE:
        // onUpdatedMessage(data.payload.data);
        break;
      case CHAT_SOCKETS_MESSAGES.DELETED_MESSAGE:
        // onDeletedMessage(data.payload.message_id);
        break;
    }
  };

  return null;
};

export default observer(ChatSocketsProvider);
