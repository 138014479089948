import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const getZones = async (filters: ZonesListFilters) => {
  logger('GET_ZONES::');

  return fetch<PaginationResponse<Zone>>('ZONES', {
    params: filters,
  });
};

export const updateCreateZone = async (
  data: ZoneForm<ZoneRule['id'], Zone['polygon']>,
  id?: Zone['id'],
) => {
  logger('CREATE_ZONE::', data);

  if (!id) {
    return fetch<Zone>('ZONES', {
      method: 'POST',
      data,
    });
  }

  return fetch<Zone>(
    'ZONE_BY_ID',
    {
      method: 'PATCH',
      data,
    },
    true,
    { id },
  );
};

export const getZoneById = async (id: Zone['id']) => {
  logger('GET_ZONE_BY_ID::');

  return fetch<Zone>('ZONE_BY_ID', {
    params: { id },
  });
};

export const getZoneRules = async (city: CityConfig['city']) => {
  logger('GET_ZONE_RULES::');

  return fetch<ZoneRule[]>('ZONE_RULES', {
    params: { city },
  });
};
