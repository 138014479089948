import styles from './styles.module.css';

type Props = {
  children?: React.ReactNode;
  onClick?: () => void;
};

const Overlay = ({ children, onClick }: Props) => {
  const handleClick = (e: React.MouseEvent) => {
    if (!onClick) return;
    if (e.target !== e.currentTarget) return;

    e.stopPropagation();
    onClick();
  };

  return (
    <div className={styles.container} onClick={handleClick}>
      {children}
    </div>
  );
};

export default Overlay;
