import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './styles.module.css';
import ChatList from './components/ChatList';
import Chat from './components/Chat';

import Action from 'components/Action';
import PageHeader from 'components/PageHeader';
import { DEFAULT_PAGINATION, PAGINATION_LIMIT } from 'constants/api';
import { getTickets, updateTicket as changeTicketStatus } from 'api/support';
import notify from 'utils/notifications';
import ChatSocketsProvider from 'providers/ChatSocketsProvider';
import chatStore from 'stores/chat';

const SupportPage = () => {
  const { selectedTicket, updateTicket, setTickets, reset, filterByType } =
    chatStore;

  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  // TODO: implement pagination
  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
    limit: PAGINATION_LIMIT[5],
  });

  const filters: TicketListFilter = useMemo(() => {
    return {
      type: filterByType,
      search,
      page: pagination.page,
      limit: pagination.limit,
    };
  }, [filterByType, search, pagination.page, pagination.limit]);

  useEffect(() => reset, []);

  useEffect(() => {
    if (filters) {
      fetchChats(filters);
    }
  }, [filters]);

  const fetchChats = async (filters: TicketListFilter) => {
    setLoading(true);

    const { data: res, error, success } = await getTickets(filters);

    setLoading(false);

    if (success) {
      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });

      setTickets(res.results);
    } else {
      notify(error.reason, 'error');
      setTickets([]);
    }
  };

  const handleArchive = async () => {
    const { success, error } = await changeTicketStatus(selectedTicket?.id!, {
      type: 'ARCHIVED',
    });

    if (success) {
      updateTicket(selectedTicket?.id!, { type: 'ARCHIVED' });
      notify('Chat archived', 'success');
    } else {
      notify(error.reason, 'error');
    }
  };

  const handleSearch = async (value: string) => {
    setSearch(value);
  };

  const isActionDisabled = loading || !selectedTicket;

  return (
    <div className={styles.container}>
      <PageHeader
        title="Support"
        onSearch={handleSearch}
        actions={
          <>
            {/* <Action
              disabled={true}
              text="New message"
              icon="pencil"
              onClick={handleActionClick('message')}
            /> */}

            <Action
              disabled={isActionDisabled}
              text="Archive chat"
              icon="delete"
              onClick={handleArchive}
            />
          </>
        }
      />

      <div className={styles.content}>
        <ChatList loading={loading} />

        {selectedTicket && <Chat />}
      </div>

      <ChatSocketsProvider />
    </div>
  );
};

export default observer(SupportPage);
