import cx from 'classnames';

import styles from './styles.module.css';

import Icon from 'components/Icon';
import Select from 'components/Select';
import { PAGINATION_LIMIT } from 'constants/api';

const TablePagination = ({
  pagination,
  onPaginationChange,
  loading,
}: {
  pagination: Pagination;
  loading?: boolean;
  onPaginationChange: (page: Pagination) => void;
}) => {
  const { count, next, previous, page, limit } = pagination;

  const currentCount = page * limit;
  const lastPage = Math.ceil(count / limit);
  const hasLastPage = page !== lastPage && page + 2 < lastPage;

  const onPageChange = (page: number) => {
    onPaginationChange({ ...pagination, page });
  };

  const onLimitChange = (limit: number) => {
    onPaginationChange({ ...pagination, page: 1, limit });
  };

  return (
    <div className={styles.pagination}>
      <div className={styles.paginationWrapper}>
        <p className={styles.total}>
          {currentCount > count ? count : currentCount} out of {count}
        </p>

        <div className={styles.pages}>
          <button
            className={cx(styles.control, { [styles.loading]: loading })}
            disabled={!previous}
            onClick={() => onPageChange(page - 1)}
          >
            <Icon name="arrow" />
          </button>

          {page - 1 > 0 && (
            <button
              className={cx(styles.item, { [styles.loading]: loading })}
              onClick={() => onPageChange(page - 1)}
            >
              {page - 1}
            </button>
          )}

          <button className={cx(styles.item, styles.active)}>{page}</button>

          {next && (
            <button
              className={cx(styles.item, { [styles.loading]: loading })}
              onClick={() => onPageChange(page + 1)}
            >
              {page + 1}
            </button>
          )}

          {next && page + 2 < lastPage && (
            <button
              className={cx(styles.item, { [styles.loading]: loading })}
              onClick={() => onPageChange(page + 2)}
            >
              {page + 2}
            </button>
          )}

          {hasLastPage && (
            <button className={cx(styles.item, styles.dots)} disabled>
              <p>...</p>
            </button>
          )}

          {hasLastPage && (
            <button
              className={cx(styles.item, { [styles.loading]: loading })}
              onClick={() => onPageChange(lastPage)}
            >
              {lastPage}
            </button>
          )}

          <button
            onClick={() => onPageChange(page + 1)}
            className={cx(styles.control, styles.right, {
              [styles.loading]: loading,
            })}
          >
            <Icon name="arrow" />
          </button>
        </div>
      </div>

      <div className={styles.limits}>
        <Select<number>
          isTop
          onSelect={onLimitChange}
          options={PAGINATION_LIMIT.map((value) => ({
            label: value,
            value,
          }))}
          value={{
            label: limit,
            value: limit,
          }}
        />
      </div>
    </div>
  );
};

export default TablePagination;
