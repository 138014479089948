import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './styles.module.css';

import notify from 'utils/notifications';
import Select from 'components/Select';
import Button from 'components/Button';
import Input from 'components/Input';
import cityStore from 'stores/city';
import { getCapitalizedText } from 'utils/format';
import { getZoneRules, updateCreateZone } from 'api/zones';
import Modal from 'components/Modal';
import { convertLatLngToGeoPoint } from 'utils/map';

type ZoneTypeData = {
  [k in ZoneType]?: ZoneRule;
};

type Props = {
  title: string;
  data: ZoneForm;
  city: CityConfig['id'];
  setIsOpen: (value: null) => void;
  setData: (value: Zone<LatLngLiteral>) => void;
};

const ZoneInfoModal = ({
  data: initialValues,
  city,
  title,
  setIsOpen,
  setData,
}: Props) => {
  const [values, setValues] = useState<ZoneForm>(initialValues);
  const [rules, setRules] = useState<ZoneTypeData>({});

  const setValue = (key: keyof ZoneForm, value: valueof<ZoneForm>) => {
    setValues({ ...values, [key]: value });
  };

  const { cities } = cityStore;
  const zone_rules = rules[values.zone_rules]?.id;

  useEffect(() => {
    fetchRules(city);
  }, []);

  const fetchRules = async (city: CityConfig['id']) => {
    const { success, data, error } = await getZoneRules(city);

    if (success) {
      const _data = data.reduce((acc, rule) => {
        acc[rule.type] = rule;

        return acc;
      }, {} as ZoneTypeData);

      setRules(_data);
    } else {
      notify(`Error: ${error?.reason}`);
    }
  };

  const handleSaveData = async () => {
    const { success, data, error } = await updateCreateZone(
      {
        city,
        name: values.name,
        status: values.status,
        zone_rules: zone_rules!,
        polygon: values.polygon.map(({ lat: latitude, lng: longitude }) => ({
          latitude,
          longitude,
        })),
      },
      values.id,
    );

    if (success) {
      notify('Zone saved successfully', 'success');

      setData({
        ...data,
        polygon: convertLatLngToGeoPoint(data.polygon),
      });

      setIsOpen(null);
    } else {
      notify(`Error: ${error?.reason}`);
    }
  };

  const citiesOptions = useMemo(() => {
    return Object.values(cities ?? {}).map((city) => ({
      value: city.id,
      label: city.city_name,
    }));
  }, [cities]);

  return (
    <Modal
      isOpen
      title={title}
      icon="plus-orange"
      onClose={() => setIsOpen(null)}
      actions={
        <>
          <Button onClick={handleSaveData} disabled={!zone_rules || !city}>
            Save
          </Button>

          <Button style="secondary-red" onClick={() => setIsOpen(null)}>
            Cancel
          </Button>
        </>
      }
    >
      <div className={styles.container}>
        <div className={styles.row}>
          <Select
            big
            disabled
            onSelect={() => {}}
            options={citiesOptions}
            value={
              city && cities
                ? { value: city, label: cities[city].city_name }
                : null
            }
          />

          <Select<Zone['status']>
            big
            disabled={!values.id}
            onSelect={() => {}}
            value={{
              value: values.status,
              label: values.status === 'CURRENT' ? 'Active' : 'Inactive',
            }}
            options={[
              { value: 'CURRENT', label: 'Active' },
              { value: 'OLD', label: 'Inactive' },
            ]}
          />
        </div>

        <div className={styles.row}>
          <Select<ZoneRule['type']>
            big
            onSelect={(value) => setValue('zone_rules', value)}
            value={{
              value: values.zone_rules as ZoneRule['type'],
              label: getCapitalizedText(values.zone_rules),
            }}
            options={Object.values(rules).map((zone) => ({
              value: zone.type,
              label: getCapitalizedText(zone.type),
            }))}
          />

          <Input
            placeholder="Name"
            defaultValue={values.name}
            onChange={(e) => setValue('name', e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default observer(ZoneInfoModal);
