import { COLORS } from 'constants/styles';
import { memo } from 'react';

type Props = {
  lightColor?: string;
  backgroundColor?: string;
  bodyColor?: string;
  className?: string;
  size?: number;
};

const ScooterIcon = ({
  lightColor = COLORS.green,
  backgroundColor = COLORS.white,
  bodyColor = COLORS.orange,
  className,
  size = 38,
}: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="2 0 38 38"
      fill="none"
      className={className}
    >
      <g filter="url(#filter0_dddd_8381_12051)">
        <circle cx="21" cy="17" r="16" fill={backgroundColor} />
      </g>
      <path
        d="M23.838 16.9649C23.6946 16.9387 23.3162 16.8916 23.0584 16.8916C22.8005 16.8916 22.7905 17.0958 22.7905 17.0958L22.2329 23.1634L23.9714 22.7717L24.3398 17.4719C24.3398 17.4719 23.9824 16.9911 23.838 16.9649ZM23.2087 17.6028C23.0125 17.5965 23.0026 17.3954 23.0155 17.2383C23.0275 17.0801 23.0345 17.0309 23.2157 17.0351C23.3959 17.0382 23.6677 17.0676 23.7743 17.0875C23.8798 17.1074 24.2293 17.586 24.2293 17.586L24.1138 17.5829C24.1138 17.5829 23.4059 17.6091 23.2087 17.6028Z"
        fill="#1F1E1D"
      />
      <path
        d="M27.4251 22.0315C28.2148 22.0315 28.8579 22.6736 28.8579 23.4644C28.8579 24.2551 28.2159 24.8972 27.4251 24.8972C26.6344 24.8972 25.9924 24.2551 25.9924 23.4644C25.9924 22.6736 26.6344 22.0315 27.4251 22.0315ZM27.4251 21.0889C26.1139 21.0889 25.0498 22.152 25.0498 23.4644C25.0498 24.7767 26.1128 25.8398 27.4251 25.8398C28.7374 25.8398 29.8005 24.7767 29.8005 23.4644C29.8005 22.152 28.7374 21.0889 27.4251 21.0889Z"
        fill="#1F1E1D"
      />
      <path
        d="M14.1304 25.8396C15.2185 25.8396 16.1006 24.958 16.1006 23.8705C16.1006 22.783 15.2185 21.9014 14.1304 21.9014C13.0423 21.9014 12.1602 22.783 12.1602 23.8705C12.1602 24.958 13.0423 25.8396 14.1304 25.8396Z"
        fill="#1F1E1D"
      />
      <path
        d="M25.5809 19.0959L23.2101 11.1347V11.1337C23.2101 11.1337 22.8656 10.0695 23.0936 9.76263C23.3216 9.45574 24.1122 8.62411 24.3502 8.87444C24.5882 9.12477 24.4199 9.36881 24.4199 9.36881C24.4199 9.36881 24.1789 9.55629 24.0007 9.93021C23.8225 10.3041 24.0644 10.853 24.0644 10.853L26.4332 18.8069L25.5809 19.0959Z"
        fill="#1F1E1D"
      />
      <path
        d="M23.2114 11.1343L25.5832 19.0945L26.82 23.0178L27.6883 22.7785L26.4355 18.8054L24.0667 10.8525L23.2114 11.1343Z"
        fill={bodyColor}
      />
      <path
        d="M24.9582 17.0294H24.2818C24.1871 17.0294 24.1107 17.1116 24.1046 17.2061C24.0893 17.4448 24.0083 17.884 23.6478 18.2632C23.1221 18.8162 23.2515 19.9306 23.1599 20.9403C23.0683 21.95 22.9767 23.1042 21.9253 23.1367C20.8738 23.1692 17.2203 23.3451 16.5801 23.3294C15.9398 23.3137 16.0275 22.4192 14.9969 21.7824C14.4125 21.4213 13.7718 21.4229 13.3266 21.492C12.9822 21.5455 12.7791 21.873 12.8526 22.2137C13.0267 23.0203 13.5079 24.3873 14.7701 24.3873C16.5604 24.3873 22.6929 24.1872 23.4288 24.1066C24.1646 24.0259 24.546 22.5303 24.6854 21.6358C24.8248 20.7413 25.0916 19.3116 25.8155 19.2582C25.6893 18.5048 24.9572 17.0283 24.9572 17.0283L24.9582 17.0294Z"
        fill={bodyColor}
      />
      <path
        d="M24.5433 9.74786C24.9596 9.74786 25.2971 9.39288 25.2971 8.95498C25.2971 8.51709 24.9596 8.16211 24.5433 8.16211C24.127 8.16211 23.7896 8.51709 23.7896 8.95498C23.7896 9.39288 24.127 9.74786 24.5433 9.74786Z"
        fill="#1F1E1D"
      />
      <path
        d="M27.538 23.8053C27.7729 23.7236 27.9004 23.4565 27.8227 23.2093L26.4974 19.0039L25.6455 19.2955L26.9724 23.5057H26.9714C27.0491 23.7529 27.303 23.887 27.538 23.8053Z"
        fill="#1F1E1D"
      />
      <path
        d="M13.0477 12.8827H14.6957V16.7227C14.6957 17.6187 15.181 17.8 15.773 17.128L19.8104 12.5413C20.3064 11.9813 20.0984 11.5173 19.3464 11.5173H17.6984V7.67734C17.6984 6.78134 17.213 6.60001 16.621 7.27201L12.5837 11.8587C12.093 12.424 12.301 12.8827 13.0477 12.8827Z"
        fill={lightColor}
      />
      <defs>
        <filter
          id="filter0_dddd_8381_12051"
          x="0.2"
          y="0.2"
          width="41.6"
          height="50.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.8" />
          <feGaussianBlur stdDeviation="0.8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8381_12051"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.2" />
          <feGaussianBlur stdDeviation="1.6" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_8381_12051"
            result="effect2_dropShadow_8381_12051"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="7.2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_8381_12051"
            result="effect3_dropShadow_8381_12051"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="12.8" />
          <feGaussianBlur stdDeviation="2.4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_8381_12051"
            result="effect4_dropShadow_8381_12051"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_8381_12051"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default memo(ScooterIcon);
