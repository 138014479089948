import { PAGINATION_LIMIT } from 'constants/api';
import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const getTickets = ({
  page,
  limit = PAGINATION_LIMIT[5],
  search,
  type = 'NEW',
}: TicketListFilter) => {
  logger('TICKETS::');

  return fetch<PaginationResponse<Ticket>, { reason: string }>(
    'SUPPORT_TICKETS',
    {
      params: {
        page,
        search,
        type,
        page_size: limit,
      },
    },
  );
};

export const updateTicket = async (
  id: number,
  data: { type: Ticket['type'] },
) => {
  logger('UPDATE_TICKET::', id);

  return fetch<Ticket, { reason: string }>(
    'SUPPORT_TICKETS_BY_ID',
    {
      method: 'PUT',
      data,
    },
    true,
    { id },
  );
};

export const getMessages = ({
  ticket,
  page,
  limit = PAGINATION_LIMIT[4],
}: MessageListFilter) => {
  logger('MESSAGES::');

  return fetch<PaginationResponse<ChatMessage>, { reason: string }>(
    'SUPPORT_MESSAGES',
    {
      params: {
        ticket,
        page,
        page_size: limit,
      },
    },
  );
};

export const sendMessage = async (data: FormData) => {
  logger('CREATE_CHAT_MESSAGE::');

  return fetch<ChatMessage, { reason: string }>('SUPPORT_CREATE_MESSAGE', {
    method: 'POST',
    data,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const readMessages = async (id: Ticket['id']) => {
  logger('READ_MESSAGES::', id);

  return fetch<ChatMessage, { reason: string }>(
    'SUPPORT_READ_MESSAGES',
    {
      method: 'POST',
    },
    true,
    { id },
  );
};
