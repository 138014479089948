import { memo, useEffect, useState } from 'react';

import styles from './styles.module.css';

import Action from 'components/Action';
import PageHeader from 'components/PageHeader';
import notify from 'utils/notifications';
import { getRemoteConfig, updateRemoteConfig } from 'api/config';
import Table, { TableCell, TableHeadCell, TableRow } from 'components/Table';
import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import { DefaultInput, DefaultSelect } from 'components/DefaultFormElements';
import { DEFAULT_CONFIG } from 'constants/config';

const AppConfigPage = () => {
  const [rawData, setRawData] = useState<RemoteConfig>({ ...DEFAULT_CONFIG });
  const [data, setData] = useState<ConfigData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    setLoading(true);

    const { data, error, success } = await getRemoteConfig();

    setLoading(false);

    const _data = data ? data : DEFAULT_CONFIG;
    const convertedData = convertToData(_data);

    setData(convertedData);
    setRawData(_data);

    if (!success) {
      notify(error.reason, 'error');
    }
  };

  const handleSave = async () => {
    setLoading(true);

    const form = data.reduce((acc, { type, value, name }) => {
      if (!name) return acc;

      return {
        ...acc,
        [name]:
          type === 'number'
            ? Number(value)
            : type === 'boolean'
            ? value === 'true'
            : value,
      };
    }, {} as RemoteConfig);

    const { data: res, error, success } = await updateRemoteConfig(form);

    setLoading(false);

    const _data = res ? res : DEFAULT_CONFIG;
    const convertedData = convertToData(_data);

    setData(convertedData);
    setRawData(_data);

    if (!success) {
      notify(error.reason, 'error');
    }
  };

  const handleAddRow = () => {
    setData((prev) => [
      {
        name: '',
        value: '',
        type: 'string',
      },
      ...prev,
    ]);
  };

  const updateData = (
    i: number,
    key: keyof ConfigData,
    value: ConfigData['value'],
  ) => {
    setData((prev) => {
      return prev.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [key]: value,
            value: key === 'type' ? '' : key === 'value' ? value : item.value,
          };
        }

        return item;
      });
    });
  };

  const handleDelete = (i: number) => {
    setData((prev) => {
      const _prev = [...prev];
      _prev.splice(i, 1);

      return _prev;
    });
  };

  return (
    <div className={styles.container}>
      <PageHeader
        title="App Config"
        actions={
          <>
            <Action text="New value" icon="plus" onClick={handleAddRow} />

            <Action text="Save changes" icon="save" onClick={handleSave} />

            <Action
              text="Revert changes"
              icon="reload"
              disabled={data.length === 0}
              onClick={() => {
                setData(convertToData(rawData));
              }}
            />
          </>
        }
      />

      <div className={styles.content}>
        <Table
          loading={loading}
          header={
            <TableRow>
              {HEADER_CONFIG.map(({ title, field, center, width }) => {
                return (
                  <TableHeadCell key={field} center={center} width={width}>
                    {title}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          }
        >
          {Object.values(data).map(({ type, name, value }, i) => (
            <TableRow key={i} disabled={name === 'version'}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>
                <DefaultSelect
                  value={type}
                  onChange={(data) => updateData(i, 'type', data)}
                >
                  <option value="string">string</option>
                  <option value="number">number</option>
                  <option value="boolean">boolean</option>
                </DefaultSelect>
              </TableCell>

              <TableCell>
                <DefaultInput
                  value={name}
                  onChange={(data) => updateData(i, 'name', data)}
                />
              </TableCell>

              <TableCell>
                {type === 'boolean' ? (
                  <DefaultSelect
                    value={`${value}`}
                    onChange={(data) => updateData(i, 'value', data)}
                  >
                    <option value={'true'}>true</option>
                    <option value={'false'}>false</option>
                  </DefaultSelect>
                ) : (
                  <DefaultInput
                    type={type === 'number' ? 'number' : 'text'}
                    value={`${value}`}
                    onChange={(data) => updateData(i, 'value', data)}
                  />
                )}
              </TableCell>

              <TableCell>
                <div className={styles.actions}>
                  {name !== 'version' && (
                    <IconButton color="red" onClick={() => handleDelete(i)}>
                      <Icon name="trash" />
                    </IconButton>
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </div>
    </div>
  );
};

const convertToData = (data: RemoteConfig): ConfigData[] => {
  return Object.entries(data).map(([name, value]) => ({
    name,
    value,
    type: typeof value as ConfigData['type'],
  }));
};

const HEADER_CONFIG = [
  { title: '#', field: 'number', center: false, width: 20 },
  { title: 'Type', field: 'type', center: false, width: 60 },
  { title: 'Name', field: 'name', center: false, width: 150 },
  { title: 'Value', field: 'value', center: false, width: 150 },
  { title: 'Actions', field: 'action', center: true, width: 100 },
] as const;

export default memo(AppConfigPage);
