import { makeAutoObservable } from 'mobx';

import { getItem, removeItem, setItem } from 'utils/storage';

class AuthStore {
  token = getItem('AUTH');
  authorised = !!this.token;
  profile: UserProfile | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthorised = (value: boolean, token?: string) => {
    this.authorised = value;

    if (token) {
      setItem('AUTH', token);
      this.token = token;
    }
  };

  setProfile = (profile: UserProfile | null) => {
    this.profile = profile;
  };

  logout = () => {
    removeItem('AUTH');

    this.token = null;
    this.setAuthorised(false);
    this.setProfile(null);
  };
}

const authStore = new AuthStore();

export default authStore;
