import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import authStore from 'stores/auth';
import { SCREENS } from 'constants/navigation';
import { getProfile } from 'api/user';
import cityStore from 'stores/city';

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const redirect = useNavigate();
  const { pathname } = useLocation();

  const { authorised, profile, setProfile, logout } = authStore;
  const { setSelectedCity } = cityStore;

  useEffect(() => {
    if (authorised && !profile) {
      fetchProfile();
    }
  }, [authorised, profile]);

  useEffect(() => {
    if (authorised && pathname.includes(SCREENS.AUTH)) {
      redirect(SCREENS.TRANSPORTS);
    } else if (!authorised && !pathname.includes(SCREENS.AUTH)) {
      redirect(SCREENS.AUTH);
    }
  }, [authorised, pathname]);

  const fetchProfile = async () => {
    const { success, data } = await getProfile();

    if (success) {
      setProfile(data);
      setSelectedCity(data.admin_userprofile?.current_city);
    } else {
      logout();
    }
  };

  return <>{children}</>;
};

export default observer(AuthProvider);
