import ReactDOM from 'react-dom';

import styles from './styles.module.css';

import Overlay from 'components/Overlay';
import Icon from '../Icon';

type Props = {
  title: string;
  icon?: string;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  actions?: React.ReactNode;
};

const Modal = ({ children, actions, title, icon, isOpen, onClose }: Props) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Overlay onClick={onClose}>
      <div className={styles.container}>
        <div className={styles.title}>
          {icon && (
            <div className={styles.icon}>
              <Icon name={icon} />
            </div>
          )}

          <h2>{title}</h2>
        </div>

        <div className={styles.content}>{children}</div>

        <div className={styles.actions}>{actions}</div>
      </div>
    </Overlay>,
    document.getElementById('modal')!,
  );
};

export default Modal;
