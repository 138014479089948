import moment from 'moment';

export const getCapitalizedText = (text: string) => {
  let readableText = text.replace(/_/g, ' ');

  readableText = readableText.toLowerCase().replace(/(^| )(\w)/g, function (x) {
    return x.toUpperCase();
  });

  return readableText;
};

export const formatDateTime = (input: string): string => {
  const now = new Date();
  const inputDate = new Date(input);

  const isSameDay = now.toDateString() === inputDate.toDateString();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  const isYesterday = yesterday.toDateString() === inputDate.toDateString();

  const config: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };

  if (isSameDay || isYesterday) {
    return inputDate.toLocaleTimeString([], config);
  } else {
    const day = inputDate.getDate().toString().padStart(2, '0');
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const year = inputDate.getFullYear();
    const formattedDate = `${day}.${month}.${year}`;

    const formattedTime = inputDate.toLocaleTimeString([], config);
    return `${formattedDate} ${formattedTime}`;
  }
};

export const formatDuration = (ms: string | number | null): string => {
  const duration = moment.duration(ms);

  if (duration.years() > 0) {
    return `${duration.years()} year${duration.years() > 1 ? 's' : ''}`;
  } else if (duration.months() > 0) {
    return `${duration.months()} month${duration.months() > 1 ? 's' : ''}`;
  } else if (duration.days() > 0) {
    return `${duration.days()} day${duration.days() > 1 ? 's' : ''}`;
  } else if (duration.hours() > 0) {
    return `${duration.hours()} hour${duration.hours() > 1 ? 's' : ''}`;
  } else if (duration.minutes() > 0) {
    return `${duration.minutes()} minute${duration.minutes() > 1 ? 's' : ''}`;
  } else {
    return `${duration.seconds()} second${duration.seconds() > 1 ? 's' : ''}`;
  }
};

export const mapCurrency: {
  [key in Currency]: string;
} = {
  USD: '$',
  EUR: '€',
};
