import { Options } from 'react-use-websocket';

import { TOKEN_PREFIX } from './api';

import { logger } from 'utils/logger';

const protocolPrefix = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
export const WS_URL = `${protocolPrefix}//${window.location.host}`;

export const SOCKETS_URLS = {
  CHAT: `wss://app-dev1.svist.sk/ws/chat/support_chat_connection/`,
};

export const getSocketOption = (token?: string | null): Options => ({
  protocols: ['Token', token?.replace(TOKEN_PREFIX, '') ?? ''],
  heartbeat: true,
  reconnectAttempts: 3,
  reconnectInterval: 10000,
  share: true,
  shouldReconnect: () => true,
});

export enum CHAT_SOCKETS_MESSAGES {
  NEW_MESSAGE = 'new_support_chat_message',
  UPDATED_MESSAGE = 'support_chat_message_updated',
  DELETED_MESSAGE = 'support_chat_message_deleted',
}

logger('WS_URL::', WS_URL);
