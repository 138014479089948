import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const getRemoteConfig = async () => {
  logger('GET_REMOTE_CONFIG::');

  return fetch<RemoteConfig | null, { reason: string }>('REMOTE_CONFIG');
};

export const updateRemoteConfig = async (data: RemoteConfig) => {
  logger('UPDATE_REMOTE_CONFIG::');

  return fetch<RemoteConfig | null, { reason: string }>(
    'UPDATE_REMOTE_CONFIG',
    {
      method: 'PUT',
      data,
    },
  );
};
