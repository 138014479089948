import styles from './styles.module.css';

import Icon from 'components/Icon';
import IconButton from 'components/IconButton';

type Props = {
  actions: {
    icon: string;
    onClick: () => void;
    disabled?: boolean;
    active?: boolean;
  }[];
};

const Controls = ({ actions }: Props) => {
  return (
    <div className={styles.controls}>
      {actions.map(({ icon, onClick, disabled, active }, index) => (
        <IconButton
          key={index}
          onClick={onClick}
          disabled={disabled}
          active={active}
        >
          <Icon name={icon} />
        </IconButton>
      ))}
    </div>
  );
};

export default Controls;
