import {
  putWorkScooter,
  unblockScooter,
  blockScooter,
  unblockBatteryScooter,
  reloadScooter,
  signalScooter,
  updateGeoScooter,
} from 'api/transport';

export const BATTERY_LEVEL_MAP = {
  EMERGENCY: 10,
  DISCHARGED: 20,
  CHARGED: 50,
} as const;

export const ACTIONS = {
  work: (id: Scooter['id']) => putWorkScooter(id, { work_status: 'In work' }),
  outWork: (id: Scooter['id']) =>
    putWorkScooter(id, { work_status: 'Out of work' }),
  unlock: (id: Scooter['id']) => unblockScooter(id),
  lock: (id: Scooter['id']) => blockScooter(id),
  battery: (id: Scooter['id']) => unblockBatteryScooter(id),
  reload: (id: Scooter['id']) => reloadScooter(id),
  signal: (id: Scooter['id']) => signalScooter(id),
  location: (id: Scooter['id']) => updateGeoScooter(id),
  wearHouse: (id: Scooter['id']) => putWorkScooter(id, { status: 'HQ' }),
} as const;
