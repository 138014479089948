import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import styles from './styles.module.css';

import PageHeader from 'components/PageHeader';
import Table, {
  Dot,
  TableCell,
  TableHeadCell,
  TableRow,
} from 'components/Table';
import { DEFAULT_PAGINATION } from 'constants/api';
import Checkbox from 'components/Checkbox';
import Action from 'components/Action';
import notify from 'utils/notifications';
import { deleteUser, getUsers, updateUser } from 'api/user';

type Id = Client['id'];

const UsersPage = () => {
  const [search, setSearch] = useState('');
  const [data, setData] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLoading, setSelectedLoading] = useState(false);
  const [selected, setSelected] = useState<Id[]>([]);
  const [order, setOrder] = useState<UsersFilter['ordering']>('id');
  const [pagination, setPagination] = useState<Pagination>({
    ...DEFAULT_PAGINATION,
  });

  const filters: UsersFilter = useMemo(() => {
    return {
      search,
      role: 'Client',
      page: pagination.page,
      limit: pagination.limit,
      ordering: order,
    };
  }, [search, pagination.page, pagination.limit, order]);

  useEffect(() => {
    if (filters) {
      fetchUsers(filters);
    }
  }, [filters]);

  const fetchUsers = async (filters: UsersFilter) => {
    setSelected([]);
    setLoading(true);

    const { data: res, success } = await getUsers(filters);

    setLoading(false);

    if (success) {
      setPagination({
        ...pagination,
        count: res.count,
        next: !!res.next,
        previous: !!res.previous,
      });

      setData(res.results);
    } else {
      setData([]);
    }
  };

  const handleActionClick = (action: keyof typeof ACTIONS) => async () => {
    setSelectedLoading(true);

    const _promise = await Promise.all(
      selected.map((id) => ACTIONS[action](id)),
    );

    setSelectedLoading(false);

    const success = _promise.filter(({ success }) => success);
    const length = _promise.length;
    const successLength = success.length;
    const failedLength = length - successLength;

    if (successLength) {
      notify(`Sucess: ${successLength}/${length}.`, 'success');

      fetchUsers(filters!);
    }

    if (failedLength) {
      notify(`Failed: ${failedLength}/${length}.`);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination({ ...DEFAULT_PAGINATION });
    setData([]);
  };

  const handleRowClick = (id: Id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((s) => s !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedAll) {
      setSelected([]);
    } else {
      setSelected(data.map(({ id }) => id));
    }
  };

  const orderClick = (
    field: keyof Client,
  ) => {
    setPagination({ ...DEFAULT_PAGINATION });

    if (field === order) {
      setOrder(`-${field}`);
    } else {
      setOrder(field);
    }
  };

  const selectedAll = !!data.length && selected.length === data.length;
  const actionsDisabled = !selected.length || selectedLoading;

  return (
    <div className={styles.container}>
      <PageHeader
        title="Scooter details"
        onSearch={handleSearch}
        searchDisabled={loading || selectedLoading}
        actions={
          <>
            <Action
              text="Block User"
              icon="delete"
              disabled={actionsDisabled}
              onClick={handleActionClick('block')}
            />

            <Action
              text="Unblock user"
              icon="lock"
              disabled={actionsDisabled}
              onClick={handleActionClick('unblock')}
            />
          </>
        }
      />

      <div className={styles.content}>
        <Table
          pagination={pagination}
          loading={loading}
          onPaginationChange={(data: Pagination) =>
            setPagination({ ...pagination, ...data })
          }
          header={
            <TableRow>
              <TableHeadCell onClick={handleSelectAll}>
                <Checkbox checked={selectedAll} />
              </TableHeadCell>

              {HEADER_CONFIG.map(({ title, field, center }) => {
                const isOrdering = order.includes(field)
                  ? order.includes('-')
                    ? 'asc'
                    : 'desc'
                  : false;

                return (
                  <TableHeadCell
                    key={field}
                    center={center}
                    order={isOrdering}
                    onClick={() => orderClick(field)}
                  >
                    {title}
                  </TableHeadCell>
                );
              })}
            </TableRow>
          }
        >
          {data.map(
            ({
              id,
              first_name,
              last_name,
              is_active,
              trip_count,
              phone,
              email,
              client_userprofile,
              language,
            }) => (
              <TableRow
                key={id}
                disabled={selectedLoading && selected.includes(id)}
                selected={selected.includes(id)}
                onClick={() => handleRowClick(id)}
              >
                <TableCell>
                  <Checkbox checked={selected.includes(id)} />
                </TableCell>

                <TableCell color="orange">ID {id}</TableCell>
                <TableCell>{first_name ?? '-'}</TableCell>
                <TableCell>{last_name ?? '-'}</TableCell>
                <TableCell center>
                  <Dot color={is_active ? 'green' : 'red'} />
                </TableCell>
                <TableCell center>{trip_count ?? 0}</TableCell>
                <TableCell>{phone ?? '-'}</TableCell>
                <TableCell>{email ?? '-'}</TableCell>
                <TableCell>{language ?? '-'}</TableCell>
              </TableRow>
            ),
          )}
        </Table>
      </div>
    </div>
  );
};

const HEADER_CONFIG = [
  { title: 'User', field: 'id', center: false },
  { title: 'Name', field: 'first_name', center: false },
  { title: 'Surname', field: 'last_name', center: false },
  { title: 'Status', field: 'is_active', center: true },
  { title: 'No. of Trips', field: 'trip_count', center: true },
  { title: 'Phone', field: 'phone', center: false },
  { title: 'Email', field: 'email', center: false },
  { title: 'Language', field: 'language', center: false },

] as const;

const ACTIONS = {
  unblock: (id: Id) => updateUser(id, { is_active: true }),
  block: (id: Id) => updateUser(id, { is_active: false }),
  delete: (id: Id) => deleteUser(id),
} as const;

export default observer(UsersPage);
