import { memo, useCallback, useState } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import cx from 'classnames';

import styles from './styles.module.css';

type Props = LatLngLiteral & {
  color?: Colors;
  zIndex?: number;
  title?: string;
};

const DotMarker = ({
  color = 'red',
  zIndex = 0,
  title,
  ...coordinates
}: Props) => {
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown((isShown) => !isShown),
    [],
  );

  return (
    <AdvancedMarker
      position={coordinates}
      onClick={handleMarkerClick}
      zIndex={zIndex}
    >
      <div className={cx(styles.dotMarker, styles[color])} />

      {title && infoWindowShown && (
        <div className={styles.infoWindow}>{title}</div>
      )}
    </AdvancedMarker>
  );
};

export default memo(DotMarker);
