import { memo } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import styles from './styles.module.css';

import { ReactComponent as Parking } from './icons/parking.svg';
import { ReactComponent as Restricted } from './icons/restricted.svg';
import { ReactComponent as NoParking } from './icons/parking-disabled.svg';
import { ReactComponent as Slow } from './icons/slow.svg';
import { ZONES } from 'constants/map';

type Props = {
  coordinates: LatLngLiteral;
  icon: ZoneType;
  onClick?: () => void;
  zIndex?: number;
};

const ZoneMarker = ({ coordinates, icon, onClick, zIndex }: Props) => {
  const Icon = getIcon(icon);

  return (
    <AdvancedMarker position={coordinates} onClick={onClick} zIndex={zIndex}>
      <div className={styles.marker}>{Icon && <Icon />}</div>
    </AdvancedMarker>
  );
};

const getIcon = (icon: ZoneType) => {
  switch (icon) {
    case ZONES.PARKING:
      return Parking;
    case ZONES.OPERATIONAL_ALLOWED_PARKING:
      return Parking;
    case ZONES.OPERATIONAL_DISABLED_PARKING:
      return NoParking;
    case ZONES.SLOW_15_KM:
      return Slow;
    case ZONES.SLOW_10_KM:
      return Slow;
    case ZONES.FORBIDDEN:
      return Restricted;
    case ZONES.TOTALLY_FORBIDDEN:
      return Restricted;
    case ZONES.FORBIDDEN_NO_USER_NOTIFICATION:
      return Restricted;
    default:
      return null;
  }
};

export default memo(ZoneMarker);
