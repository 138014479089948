import { memo } from 'react';
import { AdvancedMarker } from '@vis.gl/react-google-maps';

import ScooterIcon from './icons/scooter';
import styles from './styles.module.css';

import { BATTERY_LEVEL_MAP } from 'constants/scooter';
import { COLORS } from 'constants/styles';

type Props = LatLngLiteral & {
  battery?: number | null;
  onClick?: () => void;
  selected?: boolean;
  zIndex?: number;
};

const ScooterMarker = ({
  battery,
  onClick,
  selected = false,
  zIndex = 0,
  ...coordinates
}: Props) => {
  const lightColor = getChargeColor(battery ?? 0);

  return (
    <AdvancedMarker
      position={coordinates}
      onClick={onClick}
      zIndex={selected ? zIndex + 1 : zIndex}
      className={styles.scooterMarker}
    >
      <ScooterIcon
        size={selected ? 45 : 38}
        lightColor={lightColor}
        backgroundColor={selected ? COLORS.orange : COLORS.white}
        bodyColor={selected ? COLORS.white : COLORS.orange}
      />
    </AdvancedMarker>
  );
};

const getChargeColor = (charge?: number) => {
  if (!charge) {
    return COLORS.black;
  }

  if (charge <= BATTERY_LEVEL_MAP.DISCHARGED) {
    return COLORS.red;
  }

  if (charge <= BATTERY_LEVEL_MAP.CHARGED) {
    return COLORS.yelow;
  }

  return COLORS.green;
};

export default memo(ScooterMarker);
