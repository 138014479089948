import { makeAutoObservable } from 'mobx';

class CityStore {
  cities: Record<CityConfig['id'], CityConfig> | null = null;
  selectedCity: CityConfig['id'] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedCity = (cityId: CityConfig['id'] | null = null) => {
    if (cityId !== this.selectedCity) {
      this.selectedCity = cityId;
    }
  };

  setCityConfig = (data: CityConfig[] = []) => {
    this.cities = data.reduce((acc, city) => {
      return {
        ...acc,
        [city.id]: city,
      };
    }, {} as typeof this.cities);
  };
}

const cityStore = new CityStore();

export default cityStore;
