import cx from 'classnames';

import styles from './styles.module.css';

type Props = {
  size?: number;
  className?: string;
};

const Loader = ({ size = 8, className }: Props) => {
  return (
    <div className={cx(styles.container, className)}>
      <span
        className={styles.loader}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
      <span
        className={styles.loader}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
      <span
        className={styles.loader}
        style={{ width: `${size}px`, height: `${size}px` }}
      />
    </div>
  );
};

export default Loader;
