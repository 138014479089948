import { useState } from 'react';

import styles from './styles.module.css';

import Input from 'components/Input';
import Button from 'components/Button';
import { login } from 'api/auth';
import authStore from 'stores/auth';
import notify from 'utils/notifications';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const { setAuthorised } = authStore;

  const handleClick = async () => {
    setLoading(true);

    const { success, data, error } = await login(username, password);

    setLoading(false);

    if (success) {
      setAuthorised(true, data.token);
    } else {
      notify(error?.reason);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.background} />

      <div className={styles.form}>
        <div className={styles.content}>
          <h1>Welcome to Svišť Admin Panel</h1>

          <Input
            size="big"
            defaultValue={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            className={styles.input}
          />

          <Input
            size="big"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            defaultValue={password}
            placeholder="Password"
          />

          <Button
            className={styles.button}
            onClick={handleClick}
            loading={loading}
          >
            Log In
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
