import { observer } from 'mobx-react-lite';
import cx from 'classnames';

import { ReactComponent as AvatarIcon } from '../../icons/avatar.svg';
import styles from './styles.module.css';

import Loader from 'components/Loader';
import chatStore from 'stores/chat';
import Select from 'components/Select';

const filterOptions: SelectOption<TicketStatus>[] = [
  { value: 'NEW', label: 'New' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'RESOLVED', label: 'Resolved' },
];

type Props = {
  loading: boolean;
};

const ChatList = ({ loading }: Props) => {
  const {
    setSelectedTicket,
    selectedTicket,
    tickets,
    filterByType,
    setFilterByType,
  } = chatStore;

  return (
    <div className={styles.chatList}>
      <div className={styles.filter}>
        <Select<TicketStatus>
          icon="mail"
          value={filterOptions.find((option) => option.value === filterByType)}
          onSelect={(value) => setFilterByType(value)}
          options={filterOptions}
        />
      </div>

      {!loading ? (
        tickets.map((item) => {
          const { id, client, last_message, unread_messages_count, type } =
            item;
          const lastName = client.last_name ? `${client.last_name[0]}.` : '';
          const isSelected = selectedTicket?.id === id;

          return (
            <div
              key={id}
              onClick={() => setSelectedTicket(isSelected ? null : item)}
              className={cx(styles.chatItem, {
                [styles.active]: isSelected,
              })}
            >
              <div className={styles.bg} />

              <div
                className={cx(styles.chatItemContent, {
                  [styles.active]: isSelected,
                })}
              >
                <div className={styles.avatar}>
                  <AvatarIcon />
                </div>

                <div className={styles.chatInfo}>
                  <div className={styles.header}>
                    <p className={styles.title}>
                      {client.first_name} {lastName} (ID {client.id})
                    </p>

                    {unread_messages_count > 0 && (
                      <p className={styles.badge}>{unread_messages_count}</p>
                    )}
                  </div>

                  <span className={styles.message}>
                    <p
                      className={cx(styles.badge, styles.status, {
                        [styles.new]: type === 'NEW',
                        [styles.archived]: type === 'ARCHIVED',
                        [styles.resolved]: type === 'RESOLVED',
                      })}
                    >
                      {type.toLowerCase()}
                    </p>

                    <p className={styles.text}>{last_message}</p>
                  </span>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Loader className={styles.loader} />
      )}
    </div>
  );
};

export default observer(ChatList);
