export const GOOGLE_MAPS_API_KEY = 'AIzaSyBJ4YW7Ys_5ZxTDQPSh8ow0gyZTEqxzlgY';
export const MAP_ID = 'bdde00e79572ae6f';

export const DEFAULT_ZOOM = 12;
export const MAX_ZOOM = 20;
export const MIN_ZOOM = 3;

export const CITIES: {
  [k in CityConfig['id']]: LatLngLiteral;
} = {
  1: {
    lat: 48.14529493565606,
    lng: 17.112978659570217,
  },
  2: {
    lat: 48.548,
    lng: 18.162,
  },
  3: {
    lat: 48.591,
    lng: 17.828,
  },
};

export enum ZONES {
  PARKING = 'PARKING',
  OPERATIONAL_ALLOWED_PARKING = 'OPERATIONAL_ALLOWED_PARKING',
  OPERATIONAL_DISABLED_PARKING = 'OPERATIONAL_DISABLED_PARKING',
  SLOW_15_KM = 'SLOW_15_KM',
  SLOW_10_KM = 'SLOW_10_KM',
  FORBIDDEN = 'FORBIDDEN',
  TOTALLY_FORBIDDEN = 'TOTALLY_FORBIDDEN',
  FORBIDDEN_NO_USER_NOTIFICATION = 'FORBIDDEN_NO_USER_NOTIFICATION',
}

export const ZONES_COLORS: {
  [k in ZONES]: string;
} = {
  [ZONES.PARKING]: '#3772ff',
  [ZONES.OPERATIONAL_ALLOWED_PARKING]: '#cccccc',
  [ZONES.OPERATIONAL_DISABLED_PARKING]: '#777',
  [ZONES.SLOW_15_KM]: '#ffd400',
  [ZONES.SLOW_10_KM]: '#ffd400',
  [ZONES.FORBIDDEN]: '#1f1e1d',
  [ZONES.TOTALLY_FORBIDDEN]: '#1f1e1d',
  [ZONES.FORBIDDEN_NO_USER_NOTIFICATION]: '#ef4e4e',
} as const;

export const EDIT_ZONE_COLOR = '#fc03ad';
