import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const getProfile = async () => {
  logger('PROFILE::');

  return fetch<UserProfile>('PROFILE');
};

export const updateUser = async (
  id: UserProfile['id'],
  data: Partial<UserProfile>,
) => {
  logger('UPDATE_USER::');

  return fetch<UserProfile, { reason: string }>(
    'USER',
    {
      data,
      method: 'PATCH',
    },
    true,
    { id },
  );
};

export const deleteUser = async (id: UserProfile['id']) => {
  logger('DELETE_USER::');

  return fetch<null, { reason: string }>('USER', { method: 'DELETE' }, true, {
    id,
  });
};

export const getUsers = async (params: UsersFilter) => {
  logger('GET_USERS::');

  return fetch<PaginationResponse<Client>, { reason: string }>(
    'USERS',
    {
      params: {
        ...params,
        page_size: params.limit,
      },
    },
    true,
  );
};

export const getUserById = async (id: UserProfile['id']) => {
  logger('GET_USER_BY_ID::', id);

  return fetch<Client, { reason: string }>('USER', undefined, true, {
    id,
  });
};
