import { HTMLProps } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

type Props = HTMLProps<HTMLDivElement> & {
  active?: boolean;
  color?: 'red' | 'white';
};

const IconButton = ({
  children,
  className,
  disabled = false,
  active,
  color = 'white',
  ...props
}: Props) => {
  return (
    <div
      className={cx(
        styles.container,
        { [styles.disabled]: disabled, [styles.active]: active },
        { [styles[color]]: color },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default IconButton;
