import { SVGProps } from 'react';

import { ReactComponent as Arrow } from './assets/arrow.svg';
import { ReactComponent as Avatar } from './assets/avatar.svg';
import { ReactComponent as Bell } from './assets/bell.svg';
import { ReactComponent as Charge } from './assets/charge.svg';
import { ReactComponent as Chat } from './assets/chat.svg';
import { ReactComponent as CheckOrange } from './assets/check-orange.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Delete } from './assets/delete.svg';
import { ReactComponent as File } from './assets/file.svg';
import { ReactComponent as Filter } from './assets/filter.svg';
import { ReactComponent as Eye } from './assets/eye.svg';
import { ReactComponent as EyeCrossed } from './assets/eye-crossed.svg';
import { ReactComponent as Gear } from './assets/gear.svg';
import { ReactComponent as House } from './assets/house.svg';
import { ReactComponent as Heart } from './assets/heart.svg';
import { ReactComponent as List } from './assets/list.svg';
import { ReactComponent as LockClosed } from './assets/lock-closed.svg';
import { ReactComponent as Lock } from './assets/lock.svg';
import { ReactComponent as LogOut } from './assets/logout.svg';
import { ReactComponent as Mail } from './assets/mail.svg';
import { ReactComponent as Map } from './assets/map.svg';
import { ReactComponent as Markert } from './assets/marker.svg';
import { ReactComponent as Open } from './assets/open.svg';
import { ReactComponent as Pencil } from './assets/pencil.svg';
import { ReactComponent as Plus } from './assets/plus.svg';
import { ReactComponent as PlusOrange } from './assets/plus-orange.svg';
import { ReactComponent as Promo } from './assets/promo.svg';
import { ReactComponent as PutIn } from './assets/put-in.svg';
import { ReactComponent as PutOut } from './assets/put-out.svg';
import { ReactComponent as Reload } from './assets/reload.svg';
import { ReactComponent as Route } from './assets/route.svg';
import { ReactComponent as Save } from './assets/save.svg';
import { ReactComponent as Scooter } from './assets/scooter.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as Send } from './assets/send.svg';
import { ReactComponent as SearchOrange } from './assets/search-orange.svg';
import { ReactComponent as Sort } from './assets/sort.svg';
import { ReactComponent as SortUp } from './assets/sort-up.svg';
import { ReactComponent as SortDown } from './assets/sort-down.svg';
import { ReactComponent as Shield } from './assets/shield.svg';
import { ReactComponent as Stats } from './assets/stats.svg';
import { ReactComponent as Trash } from './assets/trash.svg';
import { ReactComponent as User } from './assets/user.svg';
import { ReactComponent as WarningRed } from './assets/warning-red.svg';
import { ReactComponent as Ring } from './assets/ring.svg';
import { ReactComponent as MinusOrange } from './assets/minus-orange.svg';


type Props = SVGProps<any> & { name: string };

const Icon = ({ ...props }: Props) => {
  switch (props.name) {
    case 'arrow':
      return <Arrow {...props} />;
    case 'avatar':
      return <Avatar {...props} />;
    case 'bell':
      return <Bell {...props} />;
    case 'charge':
      return <Charge {...props} />;
    case 'chat':
      return <Chat {...props} />;
    case 'check-orange':
      return <CheckOrange {...props} />;
    case 'close':
      return <Close {...props} />;
    case 'delete':
      return <Delete {...props} />;
    case 'file':
      return <File {...props} />;
    case 'filter':
      return <Filter {...props} />;
    case 'eye':
      return <Eye {...props} />;
    case 'eye-crossed':
      return <EyeCrossed {...props} />;
    case 'gear':
      return <Gear {...props} />;
    case 'ring':
      return <Ring {...props} />;
    case 'house':
      return <House {...props} />;
    case 'heart':
      return <Heart {...props} />;
    case 'list':
      return <List {...props} />;
    case 'lock-closed':
      return <LockClosed {...props} />;
    case 'lock':
      return <Lock {...props} />;
    case 'logout':
      return <LogOut {...props} />;
    case 'mail':
      return <Mail {...props} />;
    case 'map':
      return <Map {...props} />;
    case 'marker':
      return <Markert {...props} />;
    case 'open':
      return <Open {...props} />;
    case 'pencil':
      return <Pencil {...props} />;
    case 'plus':
      return <Plus {...props} />;
    case 'plus-orange':
      return <PlusOrange {...props} />;
    case 'promo':
      return <Promo {...props} />;
    case 'put-in':
      return <PutIn {...props} />;
    case 'put-out':
      return <PutOut {...props} />;
    case 'reload':
      return <Reload {...props} />;
    case 'route':
      return <Route {...props} />;
    case 'save':
      return <Save {...props} />;
    case 'scooter':
      return <Scooter {...props} />;
    case 'search':
      return <Search {...props} />;
    case 'search-orange':
      return <SearchOrange {...props} />;
    case 'send':
      return <Send {...props} />;
    case 'sort':
      return <Sort {...props} />;
    case 'sort-up':
      return <SortUp {...props} />;
    case 'sort-down':
      return <SortDown {...props} />;
    case 'shield':
      return <Shield {...props} />;
    case 'stats':
      return <Stats {...props} />;
    case 'trash':
      return <Trash {...props} />;
    case 'user':
      return <User {...props} />;
    case 'warning-red':
      return <WarningRed {...props} />;
    case 'minus-orange':
      return <MinusOrange {...props} />;


    default:
      return null;
  }
};

export default Icon;
