import { Fragment } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

import IconButton from 'components/IconButton';
import Icon from 'components/Icon';
import Table, { TableCell, TableHeadCell, TableRow } from 'components/Table';

interface Props {
  isOpen?: boolean;
  title?: string;
  titleBadges?: {
    text: string;
    color: Colors;
  }[];
  onClose?: () => void;
  children?: React.ReactNode;
}

const SideBarInfo = ({
  isOpen = false,
  title,
  onClose,
  titleBadges,
  children,
}: Props) => {
  return (
    <div
      className={cx(styles.container, {
        [styles.active]: isOpen,
      })}
    >
      <IconButton onClick={onClose}>
        <Icon
          name="arrow"
          className={cx(styles.closeButton, {
            [styles.active]: isOpen,
          })}
        />
      </IconButton>

      <div className={styles.content}>
        <h2 className={styles.title}>
          {title}{' '}
          {titleBadges &&
            titleBadges.map((badge, i) => (
              <Fragment key={i}>
                {i !== 0 && <span className={styles.divider} />}

                <span
                  key={i}
                  className={cx(styles.titleBadge, styles[badge.color])}
                >
                  {badge.text}
                </span>
              </Fragment>
            ))}
        </h2>

        {children}
      </div>
    </div>
  );
};

export const SideBarTable = ({
  title,
  headers,
  rows,
}: {
  title?: {
    text: string;
    color?: Colors;
    icon?: string;
  };
  headers: string[];
  rows: (string | number | React.ReactNode)[][];
}) => {
  return (
    <div className={styles.tableContainer}>
      {title && (
        <div className={styles.tableTitleContainer}>
          {title.icon && <Icon name={title.icon} className={styles.icon} />}

          <h3 className={cx(styles.tableTitle, styles[title.color || 'black'])}>
            {title.text}
          </h3>
        </div>
      )}

      <Table
        className={styles.table}
        header={
          <TableRow>
            {headers.map((header, index) => (
              <TableHeadCell key={index}>{header}</TableHeadCell>
            ))}
          </TableRow>
        }
      >
        {rows.map((row, index) => (
          <TableRow key={index}>
            {row.map((cell, index) => (
              <TableCell key={index}>{cell}</TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </div>
  );
};

export default SideBarInfo;
