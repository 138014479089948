import { memo, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import styles from './styles.module.css';

import Icon from 'components/Icon';

type Props = {
  checked: boolean;
};

const Checkbox = ({ checked }: Props) => {
  return (
    <div className={cx(styles.container, { [styles.checked]: checked })}>
      {checked && <Icon name="check-orange" />}
    </div>
  );
};

export default memo(Checkbox);
