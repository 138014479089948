import { toast } from 'react-toastify';

import { DEFAULT_ERROR } from 'constants/api';

const notify = (
  message: string = DEFAULT_ERROR,
  type: 'error' | 'warn' | 'info' | 'success' | 'default' = 'error',
) => {
  const _toast = type === 'default' ? toast : toast[type];

  _toast(message, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'light',
  });
};

export default notify;
