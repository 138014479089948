import { PAGINATION_LIMIT } from 'constants/api';
import { fetch } from 'utils/api';
import { logger } from 'utils/logger';

export const getNotifications = async ({
  search,
  page,
  ordering = 'id',
  limit = PAGINATION_LIMIT[2],
}: NotificationsListFilters) => {
  logger('NOTIFICATIONS::');

  return fetch<PaginationResponse<Notification>>('NOTIFICATIONS', {
    params: {
      page,
      search,
      ordering,
      page_size: limit,
    },
  });
};

export const createNotification = async (data: Partial<Notification>) => {
  logger('CREATE_NOTIFICATION::');

  return fetch<Notification[], { reason: string }>(
    'CREATE_NOTIFICATION',
    { method: 'POST', data },
    true,
  );
};

export const editNotification = async (
  id: Notification['id'],
  data: Partial<Notification>,
) => {
  logger('EDIT_NOTIFICATION::', id);

  return fetch<Notification, { reason: string }>(
    'EDIT_NOTIFICATION',
    { method: 'PUT', data },
    true,
    { id },
  );
};



export const acitveNotification = async (id: Notification['id']) => {
  logger('ACTIVATE_NOTIFICATION::');

  return fetch<Notification, { reason: string }>('ACTIVATE_NOTIFICATION', { method: 'POST' }, true, { id });
};



export const deacitveNotification = async (id: Notification['id']) => {
  logger('DEACTIVATE_NOTIFICATION::');

  return fetch<Notification, { reason: string }>('DEACTIVATE_NOTIFICATION', { method: 'POST' }, true, { id });
};

