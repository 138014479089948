import cx from 'classnames';

import styles from './styles.module.css';

import Icon from 'components/Icon';

type Props = {
  text: string;
  icon?: string;
  onClick: () => void;
  disabled?: boolean;
  withBorder?: boolean;
  className?: string;
  children?: React.ReactNode;
};

const Action = ({
  text,
  icon,
  onClick,
  disabled,
  children,
  withBorder,
  className,
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={cx(
        styles.container,
        {
          [styles.disabled]: disabled,
          [styles.withBorder]: withBorder,
        },
        className,
      )}
    >
      {icon && <Icon name={icon} />}

      <p className={styles.text}>{text}</p>

      {children}
    </div>
  );
};

export default Action;
